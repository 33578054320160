/* CSS Variables for Consistency */
:root {
  --popup-overlay-bg: rgba(41, 43, 61, 0.8);
  --popup-content-bg: #ffffff;
  --primary-color: #4758eb;
  --primary-hover: #3949ab;
  --danger-color: #eb4755;
  --danger-hover: #d43c3a;
  --success-color: #28a745;
  --warning-color: #ffc107;
  --text-color: #292b3d;
  --secondary-text-color: #666666;
  --border-color: #ccc;
  --legend-added-bg: rgba(0, 128, 0, 0.3);
  --legend-cropped-bg: rgba(255, 0, 0, 0.3);
  --canvas-bg: #f9f9f9;
  --overlay-bg: rgba(255, 255, 255, 0.7);
  --processing-text-color: #4758eb;
  --tooltip-bg: #333333;
  --tooltip-text: #ffffff;
}

/* Popup Overlay */
.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--popup-overlay-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
  box-sizing: border-box;
  overflow: visible;
}

/* Popup Content */
.popupContent {
  background-color: var(--popup-content-bg);
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 1200px;
  position: relative;
  overflow: visible;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  gap: 20px;
  animation: fadeIn 0.3s ease-in-out;
  z-index: 1001;
  box-sizing: border-box;
}

/* Fade-in Animation */
@keyframes fadeIn {
  from { opacity: 0; transform: scale(0.95); }
  to { opacity: 1; transform: scale(1); }
}

/* Popup Header */
.popupHeader {
  display: flex;
  background-color: #ffffff;
  flex-wrap: wrap;
  gap: 10px;
  box-sizing: border-box;
  padding-bottom: 10px;
}

/* Title */
.title {
  font-family: 'DM Serif Text', serif;
  font-size: 24px;
  color: var(--text-color);
  margin: 0;
}

/* Image and ID Container */
.imageAndIdContainer {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  padding-left: 20px;
}

/* Current Image */
.currentImage {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid var(--border-color);
}

/* ID Badge */
.idBadge {
  display: flex;
  align-items: center;
  background-color: var(--overlay-bg);
  border-radius: 12px;
  padding: 4px 8px;
  font-size: 16px;
  color: var(--text-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: transform 0.3s;
}

/* Increased z-index for tooltips */
.tooltipText {
  z-index: 1002;
}

/* ID Badge Hover Effect */
.idBadge:hover {
  transform: scale(1.05);
}

/* ID Icon */
.idIcon {
  margin-right: 6px;
  color: var(--primary-color);
  flex-shrink: 0;
}

/* Info Icon */
.infoIcon {
  margin-left: 6px;
  color: var(--secondary-text-color);
  cursor: pointer;
  transition: color 0.3s;
  flex-shrink: 0;
}

.infoIcon:hover {
  color: var(--primary-color);
}

/* Tooltip Container */
.tooltipContainer {
  position: relative;
  display: inline-block;
}

/* Tooltip Text */
.tooltipText {
  visibility: hidden;
  width: 220px;
  background-color: var(--tooltip-bg);
  color: var(--tooltip-text);
  text-align: left;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1002;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 14px;
  pointer-events: none;
}

/* Tooltip Arrow */
.tooltipText::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: var(--tooltip-bg) transparent transparent transparent;
}

/* Show Tooltip on Hover */
.tooltipContainer:hover .tooltipText {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

/* Input Container */
.inputContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  animation: fadeIn 0.5s ease-in-out;
  box-sizing: border-box;
  padding-bottom: 22px;
  padding-left: 20px;
  border-bottom: 1px solid var(--border-color);
}

/* Input Label */
.inputLabel {
  font-size: 16px;
  color: var(--text-color);
  font-weight: 500;
  animation: fadeIn 0.5s ease-in-out;
  box-sizing: border-box;
}

/* ID Input Field */
.idInput {
  padding: 8px 12px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 14px;
  width: 400px;
  max-width: 100%;
  transition: border-color 0.3s, box-shadow 0.3s;
  box-sizing: border-box;
}

.idInput:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(71, 88, 235, 0.4);
}

/* Instructional Text */
.instructionalText {
  padding: 10px;
  background-color: var(--overlay-bg);
  border-left: 4px solid var(--primary-color);
  border-radius: 4px;
  font-size: 14px;
  color: var(--text-color);
  animation: slideIn 0.5s ease-out;
  box-sizing: border-box;
}

/* Slide-in Animation for Instructional Text */
@keyframes slideIn {
  from { opacity: 0; transform: translateX(-10px); }
  to { opacity: 1; transform: translateX(0); }
}

/* Popup Body */
.popupBody {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  padding-right: 10px;
  box-sizing: border-box;
}

/* Groups Container */
.groupsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
}

/* Image Group */
.imageGroup {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-left: 10px;
  padding-bottom: 5px;
}

/* Group Title */
.groupTitle {
  margin-bottom: 10px;
  font-size: 1.2em;
  color: var(--text-color);
  font-family: 'Lexend Deca', sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Images Row */
.imagesRow {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  box-sizing: border-box;
}

/* Image Wrapper */
.imageWrapper {
  border: 2px solid transparent;
  border-radius: 8px;
  padding: 4px;
  cursor: pointer;
  position: relative;
  transition: border-color 0.3s, box-shadow 0.3s, transform 0.2s;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 0;
}

.imageWrapper:hover {
  border-color: var(--primary-color);
  transform: scale(1.02);
}

.imageWrapper:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(71, 88, 235, 0.4);
}

.selected {
  border: 3px solid var(--primary-color);
  box-shadow: 0 0 10px rgba(71, 88, 235, 0.5);
}

/* Checkmark Overlay */
.checkmark {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: none;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.selected .checkmark {
  display: flex;
}

/* Image Styling */
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
  display: block;
}

/* Labels Container */
.labelsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
  width: 100%;
  padding: 0 4px;
  box-sizing: border-box;
  overflow: hidden;
}

/* Image Main Label */
.imageMainLabel {
  text-align: center;
  font-size: 14px;
  color: var(--text-color);
  margin: 0;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

/* Image Sub Label */
.imageSubLabel {
  text-align: center;
  font-size: 12px;
  color: var(--secondary-text-color);
  margin: 2px 0 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

/* Popup Footer */
.popupFooter {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding-top: 10px;
  border-top: 1px solid var(--border-color);
  box-sizing: border-box;
  background-color: var(--popup-content-bg);
}

/* Import Button */
.importButton {
  background-color: var(--primary-color);
  color: #ffffff;
  border: none;
  padding: 12px 24px;
  border-radius: 24px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.2s;
  display: flex;
  align-items: center;
  gap: 10px;
}

.importButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.importButton:not(:disabled):hover {
  background-color: var(--primary-hover);
  transform: translateY(-2px);
}

.importButton:not(:disabled):focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(71, 88, 235, 0.4);
}

/* Cancel Button */
.cancelButton {
  background-color: var(--danger-color);
  color: #ffffff;
  border: none;
  padding: 12px 24px;
  border-radius: 24px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.2s;
}

.cancelButton:hover {
  background-color: var(--danger-hover);
  transform: translateY(-2px);
}

.cancelButton:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(235, 71, 85, 0.4);
}

/* Loader Container */
.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  box-sizing: border-box;
}

/* Error Message */
.errorText {
  color: var(--danger-color);
  font-size: 16px;
  text-align: center;
}

/* Success Message */
.successText {
  color: var(--success-color);
  font-size: 16px;
  text-align: center;
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .popupContent {
    max-width: 1000px;
  }

  .imageWrapper {
    width: 140px;
    height: 140px;
  }

  .groupTitle {
    font-size: 1.1em;
  }

  .imagesRow {
    gap: 14px;
  }

  .imageMainLabel {
    font-size: 13px;
  }

  .imageSubLabel {
    font-size: 11px;
  }

  .importButton,
  .cancelButton {
    padding: 10px 20px;
    font-size: 15px;
  }

  .idBadge {
    font-size: 15px;
    padding: 3px 7px;
  }

  .idIcon {
    width: 16px;
    height: 16px;
  }

  .idText {
    font-size: 15px;
  }

  .currentImage {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 768px) {
  .popupContent {
    max-width: 90%;
    padding: 15px;
  }

  .imageWrapper {
    width: 130px;
    height: 130px;
  }

  .groupTitle {
    font-size: 1em;
  }

  .imagesRow {
    gap: 12px;
  }

  .imageMainLabel {
    font-size: 12px;
  }

  .imageSubLabel {
    font-size: 10px;
  }

  .importButton,
  .cancelButton {
    padding: 10px 20px;
    font-size: 14px;
  }

  .idBadge {
    font-size: 14px;
    padding: 3px 6px;
  }

  .idIcon {
    width: 14px;
    height: 14px;
  }

  .idText {
    font-size: 14px;
  }

  .imagesRow {
    gap: 10px;
  }

  .imageWrapper {
    width: 120px;
    height: 120px;
  }

  .inputContainer {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .currentImage {
    width: 45px;
    height: 45px;
  }
}

@media (max-width: 480px) {
  .popupContent {
    max-width: 95%;
    padding: 10px;
  }

  .popupHeader h2 {
    font-size: 20px;
  }

  .idBadge {
    font-size: 13px;
    padding: 2px 5px;
  }

  .idIcon {
    width: 12px;
    height: 12px;
  }

  .idText {
    font-size: 13px;
  }

  .imagesRow {
    gap: 8px;
  }

  .imageWrapper {
    width: 100px;
    height: 100px;
  }

  .groupTitle {
    font-size: 1em;
  }

  .imageMainLabel {
    font-size: 11px;
  }

  .imageSubLabel {
    font-size: 9px;
  }

  .importButton,
  .cancelButton {
    padding: 8px 16px;
    font-size: 13px;
  }

  .checkmark {
    width: 20px;
    height: 20px;
    font-size: 14px;
  }

  .idInput {
    width: 100%;
    margin-top: 10px;
  }

  .imageAndIdContainer {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .instructionalText {
    font-size: 12px;
  }

  .currentImage {
    width: 40px;
    height: 40px;
  }
}
