/* Popup Overlay and Container (unchanged) */
.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Lexend Deca', sans-serif;
}

.popupContainer {
  background: #ffffff;
  width: 90%;
  height: 90%;
  max-width: 1200px;
  max-height: 90%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  font-family: 'Lexend Deca', sans-serif;
}

/* 
  HEADER
*/
.exportsTopBar {
  display: flex;
  align-items: center;
  gap: 12px;
  background: #ffffff;
  padding: 12px 20px;
  border-bottom: 1px solid #E0E1EB;
}

.exportConfigTitle {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #292B3D;
}

/* Just a wrapper for the last published date (which we will move to the footer). */
.exportInfo {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 16px;
}

.lastPublished {
  font-size: 12px;
  color: #555;
}

.notPublished {
  font-size: 12px;
  color: #aaa;
}

.noExportSelected {
  flex: 1; /* Take up the available space */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center */
  align-items: center;     /* Horizontally center */
  text-align: center;
  padding: 40px 20px;
  color: #555;
  background-color: #f7f7fc;
  font-size: 16px;
}

.noExportSelected p {
  margin-top: 16px;
  font-size: 18px;
}

.noExportIcon {
  font-size: 48px;
  color: #ffcc00; /* A distinct color to grab attention */
}

/* 
  BUTTONS 
*/
.createExportButton,
.deleteExportButton,
.cancelCreateButton,
.confirmCreateButton {
  cursor: pointer;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-family: 'Lexend Deca', sans-serif;
  transition: background-color 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.createExportButton {
  background-color: #5CD685;
  border-radius: 50%;
  width: 36px; 
  height: 36px;
}

.createExportButton:hover {
  background-color: #94D1A8 !important;
}

.deleteExportButton,
.cancelCreateButton {
  background-color: #EB4755;
  border-radius: 50%;
  width: 36px; 
  height: 36px;
}

.cancelCreateButton,
.deleteExportButton:hover {
  background-color: #E0858C !important;
}

.confirmCreateButton {
  background-color: #5CD685;
  border-radius: 50%;
  width: 36px; 
  height: 36px;
}

.confirmCreateButton:hover {
  background-color: #94D1A8 !important;
}
/* 
  MAIN CONTENT AREA 
*/
.popupContent {
  display: flex;
  flex: 1;
  overflow-y: auto;
  background-color: #f7f7fc;
}

/* 
  LEFT SIDE
*/
.leftSide {
  flex: 1;
  padding: 16px 20px;
  background-color: #ffffff;
  margin: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* 
  SMALL HEADER INSIDE THE LEFT SIDE 
*/
.leftSideHeader {
  font-size: 16px;
  font-weight: 600;
  color: #292B3D;
  margin: 0;
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
}

/* 
  CHECKBOXES 
*/
.checkboxWrapper {
  display: grid;
  grid-template-columns: auto 1fr; /* First column for checkbox, second for label */
  align-items: center;
  gap: 8px ;
  width: 100% ;
}

.checkboxWrapper input[type="checkbox"] {
  justify-self: start ;
}

.checkboxWrapper label {
  justify-self: start ;
  white-space: nowrap ;
}


/* 
  RIGHT SIDE
*/
.rightSide {
  flex: 1;
  padding: 16px 20px;
  margin: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* remove:  height: calc(100% - 40px); */
}

/* 
  HEADERS ON THE RIGHT SIDE 
*/
.rightSideHeader {
  font-size: 16px;
  font-weight: 600;
  color: #292B3D;
  margin: 0;
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
}

/* 
  We'll wrap each set (available columns, export columns) in a "columnsContainer"
  that is 50% height
*/
.columnsContainer {
  flex: 1; /* Each container will grow to fill half of .rightSide */
  display: flex;
  flex-direction: column;
  min-height: 0; /* helps avoid flex overflow issues */
}

/* 
  The actual scrollable list 
*/
.columnsList {
  flex: 1; /* fill up remainder of container */
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #fafafa;
  overflow-y: auto;
  padding: 8px;
  margin-top: 8px; /* space under the header */
}

/* 
  COLUMN ITEM
  – ensure consistent height, center everything
*/
.columnItem {
  display: flex;
  align-items: center;
  justify-content: space-between; /* push remove button to the right */
  background-color: #F3F3F7;
  border: 1px solid #E0E1EB;
  border-radius: 6px;
  padding: 0px 8px;
  margin-bottom: 2px;
  font-size: 14px;
  color: #292B3D;
  transition: background-color 0.3s, box-shadow 0.3s;
  min-height: 36px; /* ensure consistent row height */
}

.columnItem:hover {
  background-color: #E0E1EB;
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);
}

/* left side of the item: icon + name + mapping arrow */
.columnItemLeft {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* 
  Mapped name container 
*/
.mappingContainer {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0px 8px;
  background-color: #ffffff;
  border: 1px solid #E0EBE4;
  border-radius: 4px;
}

.mappingArrow {
  color: #999;
}

.mappingInput {
  border: none;
  outline: none;
  background: transparent;
  font-size: 14px;
  padding: 0px;
  width: 80px;
  text-align: left;
}

/* 
  remove button 
*/
.removeColumnButton {
  background-color: #EB4755;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin: 0px;
  font-size: 10px;
  padding: 6px;
  transition: background-color 0.3s;
}

.removeColumnButton:hover {
  background-color: #E0858C !important;
}

.placeholderText {
  font-size: 12px;
  color: #aaa;
  text-align: center;
}

/* 
  FOOTER 
  – last published on bottom-left, buttons on bottom-right
*/
.popupFooter {
  padding: 12px 20px;
  border-top: 1px solid #E0E1EB;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footerLeft {
  font-size: 12px;
  color: #555;
}

.footerRight {
  display: flex;
  gap: 12px; /* spacing between “View,” “Save,” “Save & Publish” */
}



.viewButton {
  background-color: #5CD685;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  margin-right: 16px;
  font-size: 14px;
  /* use the same vertical padding as .saveButton / .publishButton */
  padding: 8px 14px;
  transition: background-color 0.3s;
  font-family: 'Lexend Deca', sans-serif;
  text-decoration: none;
}

.viewButton:hover {
  background-color: #94D1A8 !important;
  text-decoration: none;
}

.saveButton,
.publishButton {
  background-color: #4758EB;
  color: #fff;
  border: none;
  padding: 8px 14px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Lexend Deca', sans-serif;
  transition: background-color 0.3s;
}

.saveButton:hover,
.publishButton:hover {
  background-color: #848EE0 !important;
}


/* 
  ERROR MESSAGE 
*/
.errorMessage {
  background-color: #f8d7da;
  color: #721c24;
  padding: 8px 20px;
  margin: 0;
  font-size: 14px;
  border-top: 1px solid #f1b9bd;
  border-bottom: 1px solid #f1b9bd;
}

/* 
  For the new React dropdowns (react-select). 
  react-select auto‐injects classes, 
  you can refine them if you like. 
*/
.reactSelectContainer {
  min-width: 160px;
}
/* ... existing CSS ... */

/* ======== UNSAVED CHANGES MESSAGE ======== */
.unsavedChangesMessage {
  display: flex;
  align-items: center;
  background-color: #fff3cd; /* Light yellow background */
  color: #856404; /* Dark yellow text */
  padding: 10px 20px;
  border-left: 4px solid #ffeeba; /* Yellow border */
  font-size: 14px;
}

.unsavedIcon {
  margin-right: 8px;
  color: #856404; /* Match the text color */
}

/* ... existing CSS ... */

/* Tooltip Wrapper - wraps the icon or the text that triggers the tooltip */
.tooltipWrapper {
  position: relative; /* so .tooltipText can be absolutely positioned */
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

/* The small icon or question mark that indicates help is available */
.tooltipIcon {
  font-size: 14px;
  color: #888;
  margin-left: 6px; /* small spacing from the label */
}

/* The tooltip's text bubble */
.tooltipText {
  visibility: hidden;
  opacity: 0;
  width: 200px; /* Adjust as needed */
  background-color: rgba(0, 0, 0, 0.85);
  color: #fff;
  text-align: left;
  border-radius: 4px;
  padding: 8px;
  position: absolute;
  z-index: 1000;

  /* Position the tooltip above the icon */
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);

  /* Transition for hover effect */
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}

/* Arrow on the bottom of tooltip (optional styling) */
.tooltipText::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -6px;
  border-width: 6px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.85) transparent transparent transparent;
}

/* Show the tooltipText on hover of the wrapper */
.tooltipWrapper:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}

/* A small note for better label wrapping + icon alignment */
.configLabel {
  display: inline-flex;
  align-items: center;
  gap: 6px;
}

/* Example for columns list if you want to show custom tooltip instead of 'title' attribute */
.columnItemWithTooltip {
  position: relative; /* for the tooltip to absolutely position */
}

/* ... existing CSS ... */


