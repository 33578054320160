/* src/components/css/ImageGeneration.module.css */

.topBar {
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
  background-color: #ffffff;
  color: #4758EB;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  gap: 10px;
  flex-wrap: wrap; /* Allows items to wrap if space is limited */
}

.headerTextContainer {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 40px;
  flex-grow: 0;
}

.clientDropdown {
  min-width: 300px;
  font-size: 12px;
}

.backButton {
  background-color: #d0d0d0;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.backButton:hover {
  background-color: #7c7c7c;
}

.refreshButton {
  background-color: #d0d0d000;
  color: #d0d0d0;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-left: 0px;
}

.refreshButton:hover {
  color: #4758EB;
  background-color: #ffffff !important;
}

.feedViewerVersion {
  font-size: 14px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 600;
  color: #4758EB;
}

.headerSubtitle {
  font-size: 22px;
  font-family: 'DM Serif Text', serif;
  color: #292B3D;
}

.headerImage {
  height: 100px;
  width: auto;
  padding-top: 20px;
  object-fit: contain;
  margin-left: auto;
}

.imageGenerationContainer {
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 5px;
}

h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.columnSelectionContainer {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.columnList {
  flex: 1;
  margin-right: 30px;
}

.moveButton {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 0 20px;
}

.moveButton:hover {
  background-color: #3949ab;
}

.tableContainer {
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  background-color: #ffffff;
  margin-bottom: 80px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
  overflow-y: hidden;
  position: relative; /* Added position relative */
}

.tableWrapper {
  position: relative; /* Added position relative */
  overflow-x: auto;
  overflow-y: hidden;
}

.table {
  width: max-content;
  border-collapse: collapse;
}

th, td {
  max-width: 450px;
  min-width: 50px;
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
}

tr:hover {
  background-color: #f5f5f5;
}

.imageContainer {
  position: relative;
  display: inline-block;
  padding: 0px;
  width: 70px;
  height: 90px;
  margin-right: 5px;
  text-align: center;
  transition: opacity 0.3s ease-in-out, border 0.3s ease-in-out;
  box-sizing: border-box;
}

.imageStyle {
  width: 100%;
  height: 60px;
  padding: 0px;
  padding-top: 5px;
  object-fit: contain;
  border-radius: 8px;
  box-sizing: border-box;
}

.imageText {
  margin-top: 5px;
  font-size: 10px !important;
  color: #292B3D;
  font-family: 'Lexend Deca', sans-serif;
}

.imageOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  color: #292B3D;
  display: none;
  align-items: center;
  justify-content: center;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: bold;
  transition: opacity 0.2s ease-in-out;
  font-size: 10px !important;
  border-radius: 8px;
  cursor: pointer;
}

.imageContainer:hover .imageOverlay {
  display: flex;
}

.combinedFilterContainer *:not(h1):not(h2):not(h3) {
  font-family: 'DM Sans', sans-serif;
  font-size: 12px;
}

.combinedFilterContainer {
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
}

.filterContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}

.filterDropdown {
  min-width: 250px;
  font-family: 'Lexend Deca', sans-serif;
}

.filterTypeSelect {
  padding: 8px;
  font-family: 'Lexend Deca', sans-serif;
  background-color: #f4f4f4;
  border: 1px solid #e0e1eb;
  border-radius: 4px;
  transition: border-color 0.3s;
}

.filterTypeSelect:focus {
  border-color: #4758EB;
}

.filterInput {
  padding: 8px;
  min-width: 200px;
  border: 1px solid #e0e1eb;
  border-radius: 4px;
  font-family: 'Lexend Deca', sans-serif;
  transition: border-color 0.3s;
}

.filterInput:focus {
  border-color: #4758EB;
}

.sortColumnDropdown {
  min-width: 250px;
  max-width: 300px;
}

.sortOrderSelect {
  width: 120px;
}

.removeFilterButton {
  background-color: #EB4755;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  width: 20px;
  height: 20px;
  font-size: 10px!important;
}

.removeFilterButton:hover {
  background-color: #E0858C !important;
}

.addFilterButton {
  background-color: #5CD685;
  color: #ffffff;
  border: none;
  padding: 10px!important;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px!important;
  height: 40px!important;
}

.addFilterButton:hover {
  background-color: #94D1A8 !important;
}

.applyFilterButton {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-right: 9px;
  padding-left: 11px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px!important;
  height: 40px!important;
}

.applyFilterButton:hover {
  background-color: #848EE0 !important;
}

.filterSection {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sortByContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-top: 10px;
}

.sortByGroup {
  display: flex;
  align-items: center;
  gap: 10px;
}

.buttonGroup {
  display: flex;
  align-items: center;
  gap: 0px;
}

.filterRow {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 0px;
}

.logicSelect {
  width: 80px;
  padding: 5px;
  font-family: 'Lexend Deca', sans-serif;
  background-color: #f4f4f4;
  border: 1px solid #e0e1eb;
  border-radius: 4px;
  transition: border-color 0.3s;
  text-align: center;
}

.logicSelect:focus {
  border-color: #4758EB;
}

.applyFilterButton:disabled:hover {
  background-color: #CCCCCC !important;
}

.paginationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  width: 600px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 100;
  gap: 10px;
  padding: 10px 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-left: auto;
  margin-right: auto;
}

.paginationButton {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.paginationButton:hover {
  background-color: #848EE0 !important;
}

.paginationButton:disabled {
  background-color: #ccc !important;
  cursor: not-allowed;
}

.paginationButton:disabled:hover {
  background-color: #CCCCCC !important;
}

.pageInput {
  width: 40px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px;
  font-size: 14px;
  font-family: 'Lexend Deca', sans-serif;
}

.pageInput:focus {
  border-color: #4758EB;
  outline: none;
}

/* TOGGLE BUTTON */
.toggleSwitch {
  display: flex;
  align-items: center;
  background-color: #e0e1eb;
  border-radius: 24px;
  padding: 5px;
  width: fit-content;
  margin: 20px 0;
  gap: 10px; /* Add gap for spacing between buttons */
  justify-content: space-between; /* Align buttons beside each other */
}

.toggleButton {
  background-color: transparent;
  border: none;
  color: #292B3D;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 20px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.generateMode:hover {
  background-color: #4758EB !important;
  color: white !important;
}

.selectorMode:hover {
  background-color: #5CC0D6 !important;
  color: white !important;
}

.transformMode:hover {
  background-color: #EB7B47 !important;
  color: white !important;
}

.generateActiveButton {
  background-color: #4758EB;
  color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.selectorActiveButton {
  background-color: #5CC0D6;
  color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.transformActiveButton {
  background-color: #EB7B47;
  color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.generateActiveButton:hover {
  background-color: #4758EB !important;
  color: white !important;
}

.selectorActiveButton:hover {
  background-color: #5CC0D6 !important;
  color: white !important;
}

.transformActiveButton:hover {
  background-color: #EB7B47 !important;
  color: white !important;
}

.generateHeader th {
  background-color: #4758EB !important;
  color: white;
  font-family: 'Lexend Deca', sans-serif;
}

.selectorHeader th {
  background-color: #5CC0D6 !important;
  color: white;
  font-family: 'Lexend Deca', sans-serif;
}

.transformHeader th {
  background-color: #EB7B47 !important;
  color: white;
  font-family: 'Lexend Deca', sans-serif;
}

.selectedImageContainer {
  border: 3px solid #5CC0D6;
  border-radius: 8px;
  box-sizing: border-box;
}

.imageContainer {
  transition: opacity 0.3s ease-in-out, border 0.3s ease-in-out;
}

.spinnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
}

/* Image type label */
.imageTypeLabel {
  position: absolute;
  top: 4px; /* Adjusts the position to the top */
  right: 4px; /* Adjusts the position to the right */
  padding: 2px 5px; /* Reduces the padding to make the label smaller */
  font-size: 10px; /* Adjusts the font size for better readability */
  color: white;
  background-color: #000; /* Default background, will be overridden */
  border-radius: 3px;
  font-family: 'Lexend Deca', sans-serif;
  line-height: 1;
  display: inline-block;
  width: auto; /* Ensures the label only takes up necessary space */
  height: auto; /* Ensures the label only takes up necessary space */
}

.storageInfo {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #292B3D;
  margin-left: 10px;
}

.storageInfo span {
  font-size: 12px;
  font-family: 'Lexend Deca', sans-serif;
  color: #d0d0d0;
}

.storageInfo svg {
  color: #d0d0d0;
}

/* New styles for credit balance */
.creditInfo {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #292B3D;
  margin-left: 20px;
}

.creditInfo span {
  font-size: 12px;
  font-family: 'Lexend Deca', sans-serif;
  color: #d0d0d0;
}

.creditInfo svg {
  color: #d0d0d0;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  left: 0;
  top: 0; /* Optional: If you want it to stick to the top when scrolling vertically */
  z-index: 2;
  background-color: #ffffff;
  padding: 10px 0;
}

/* New rightButtons container */
.rightButtons {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Styles for Bulk Actions button */
.bulkActionsButton {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: all 0.2s ease;
}

.bulkActionsButton:hover {
  background-color: #848EE0 !important;
}

/* Adjusted exportButton if needed */
.exportButton {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.exportButton:hover {
  background-color: #848EE0 !important;
}

.clientError {
  display: flex;
  align-items: center;
  color: red; /* Adjust color as needed */
  margin-top: 8px;
}

/* Additional Styles (if any) */
.selectedRow {
  background-color: #e0e1eb !important;
}

.checkbox {
  width: 16px;
  height: 16px;
}

.logoutBtn {
  background-color: #E0858C;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.logoutBtn:hover {
  background-color: #EB4755 !important;
}

.schedulerButton {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-left: 10px; /* Add some spacing if needed */
  margin-right: -5px;
}

.schedulerButton:hover {
  background-color: #848EE0 !important;
}


/* Add these styles at the end of your existing CSS file */

/* Styles for the refresh status messages */
.refreshStatusContainer {
  margin: 20px;
  font-family: 'Lexend Deca', sans-serif;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.refreshStatusMessage, .refreshErrorMessage {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  font-size: 14px;
}

.refreshStatusMessage {
  background-color: #d4edda; /* Light green background */
  color: #155724;            /* Dark green text */
  border: 1px solid #c3e6cb;
}

.refreshErrorMessage {
  background-color: #f8d7da; /* Light red background */
  color: #721c24;            /* Dark red text */
  border: 1px solid #f5c6cb;
}

.statusIcon {
  margin-right: 10px;
  font-size: 20px;
}


/* Custom styles for react-select */
.react-select__control {
  min-width: 300px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.react-select__control--is-focused {
  border-color: #4758EB;
  box-shadow: 0 0 0 1px #4758EB;
}

.react-select__menu {
  font-size: 12px;
}

.react-select__option--is-selected {
  background-color: #4758EB;
  color: white;
}

.react-select__option:hover {
  background-color: #6c7bbf;
  color: white;
}

.react-select__placeholder {
  color: #999;
}

.react-select__single-value {
  color: #333;
}
