/* src/components/css/ClientDashboard.module.css */

@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@400;600&family=DM+Sans:wght@400;500&family=DM+Serif+Text&display=swap');

/* Top Bar Styling */
.topBar {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 20px;
  background-color: #ffffff;
  color: #4758EB;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  gap: 10px;
}

/* Logout Button Styling */
.logoutBtn {
  background-color: #E0858C; /* Matching backButton background */
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.logoutBtn:hover {
  background-color: #EB4755 !important; /* Matching backButton hover */
}

.logoutBtn svg {
  margin-right: 0; /* Remove margin since button is circular */
}

/* Header Text Container */
.headerTextContainer {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 40px;
  flex-grow: 1; /* Allows it to take available space */
}

.feedViewerVersion {
  font-size: 14px;
  font-weight: 600;
  color: #4758EB;
  font-family: 'Lexend Deca', sans-serif;
}

.headerSubtitle {
  font-size: 22px;
  font-family: 'DM Serif Text', serif;
  color: #292B3D;
  margin-top: 4px;
}

/* Header Image Styling */
.headerImage {
  height: 100px;
  width: auto;
  padding-top: 20px;
  object-fit: contain;
  margin-left: auto;
}

/* Dashboard Content Styling */
.dashboardContent {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

/* Section Container Styling */
.sectionContainer {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sectionContainer h3 {
  font-family: 'Lexend Deca', sans-serif;
  color: #4758EB;
  margin-bottom: 10px;
}

.sectionContainer p {
  font-family: 'DM Sans', sans-serif;
  color: #292B3D;
  margin-bottom: 20px;
}

/* Button Group Styling */
.buttonGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

/* Dashboard Button Styling */
.dashboardBtn {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 12px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
}

.dashboardBtn:hover {
  background-color: #848EE0 !important;
}

.dashboardBtn:disabled {
  background-color: #d0d0d0; /* Light gray background */
  color: #a0a0a0; /* Gray text */
  cursor: not-allowed; /* Not-allowed cursor */
}

.dashboardBtn:disabled .icon {
  color: #a0a0a0; /* Gray icon color */
}

.dashboardBtn .icon {
  margin-right: 8px;
}

.dashboardBtn:disabled:hover {
  background-color: #d0d0d0 !important; /* Light gray background */
}


/* Responsive Design */
@media (max-width: 768px) {
  .dashboardBtn {
    width: 100%;
  }

  .topBar {
    flex-direction: column;
    align-items: flex-start;
  }

  .headerImage {
    margin-left: 0;
    margin-top: 10px;
  }
}

