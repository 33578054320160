@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');

.pageContainer {
  padding: 20px;
  font-family: 'Lexend Deca', sans-serif;
}

/* Unified Top Section */
.topSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.textContainer {
  flex: 1;
  min-width: 250px;
  margin-right: 20px;
}

.headerTitle {
  font-size: 28px;
  color: #292B3D;
  margin: 0 0 10px 0;
}

.description {
  font-size: 16px;
  color: #555555;
  line-height: 1.5;
}

.headerImage {
  width: 200px;
  height: auto;
  max-width: 100%;
}

/* Select Image Columns */
.section {
  margin-top: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sectionHeader {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.sectionHeader h4 {
  margin: 0;
  font-size: 20px;
  color: #292B3D;
  font-family: 'Lexend Deca', sans-serif;
}

.tooltip {
  position: relative;
  display: inline-block;
  margin-left: 10px;
  color: #d0d0d0;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: #292B3D;
  color: #fff;
  text-align: left;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 25px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.multiSelect {
  font-family: 'Lexend Deca', sans-serif;
}

.checkboxGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.checkboxLabel input {
  margin-right: 5px;
}

.scopeGroup {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.scopeButton {
  padding: 10px 20px;
  border: 1px solid #d0d0d0;
  border-radius: 20px;
  color: #292B3D;
  background-color: transparent;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  transition: all 0.3s ease;
}

.scopeButton:hover {
  color: #292B3D !important;
  background-color: #E0E9EB !important;
  border-color: #E0E9EB !important;
}

.activeScopeButton {
  background-color: #5CC0D6;
  color: #ffffff;
  border-color: #5CC0D6;
}

.actionGroup {
  display: flex;
  align-items: center;
  gap: 10px;
}

.actionSelect {
  width: 200px;
}

.applyButton {
  background-color: #5CD685;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: all 0.2s ease;
}

.applyButton:hover {
  background-color: #94D1A8 !important;
}

.applyButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loadingOverlay p {
  margin-top: 10px;
  font-size: 16px;
  color: #4758EB;
}

/* Responsive Design */
@media (max-width: 768px) {
  .topSection {
    flex-direction: column;
    align-items: flex-start;
  }

  .headerImage {
    margin-top: 20px;
    width: 150px;
  }

  .textContainer {
    margin-right: 0;
  }

  .scopeGroup {
    flex-direction: column;
  }

  .actionGroup {
    flex-direction: column;
    align-items: stretch;
  }

  .actionSelect {
    width: 100%;
  }

  .applyButton {
    width: 100%;
  }
}
