/* src/popups/PopupBulkActionPages/UncropperPage.module.css */

/* You can copy the contents from TransformerPage.module.css and modify as needed */

/* Example: */
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');

.pageContainer {
  padding: 20px;
  font-family: 'Lexend Deca', sans-serif;
  min-height: 100vh;
}

/* Header Section: Flex container for description and image */
.headerSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Allows items to wrap on smaller screens */
}

/* Description styling */
.description {
  flex: 1 1 70%; /* Takes up 60% of the container */
  margin-right: 20px;
  color: #292B3D;
  font-size: 16px;
  line-height: 1.5;
}

/* Header Image styling */
.headerImage {
  flex: 1 1 25%; /* Takes up 35% of the container */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px; /* Adjust for spacing on smaller screens */
}

.headerImage img {
  max-width: 100%; /* Desired width */
  height: auto; /* Maintains aspect ratio */
  object-fit: contain; /* Ensures the image scales correctly */
  border-radius: 8px; /* Optional: adds rounded corners */
}

/* Description and Image responsiveness */
@media (max-width: 768px) {
  .description {
    flex: 1 1 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .headerImage {
    flex: 1 1 100%;
    justify-content: center;
  }

  .headerImage img {
    width: 100px; /* Smaller size on mobile devices */
  }
}

.section {
  margin-top: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sectionHeader {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.sectionHeader h4 {
  margin: 0;
  font-size: 20px;
  color: #292B3D;
  font-family: 'Lexend Deca', sans-serif;
}

.tooltip {
  position: relative;
  display: inline-block;
  margin-left: 10px;
  color: #d0d0d0;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: #292B3D;
  color: #fff;
  text-align: left;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 25px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.multiSelect {
  font-family: 'Lexend Deca', sans-serif;
}

.checkboxGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}

.checkboxLabel input {
  margin-right: 5px;
  cursor: pointer;
}

.scopeGroup {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.scopeButton {
  padding: 10px 20px;
  border: 1px solid #d0d0d0;
  border-radius: 20px;
  color: #292B3D;
  background-color: transparent;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  transition: all 0.3s ease;
  font-size: 14px;
  min-width: 120px;
  text-align: center;
}

.scopeButton:hover {
  color: #292B3D !important;
  background-color: #EBE2E0 !important;
  border-color: #EBE2E0 !important;
}

.activeScopeButton {
  background-color: #4758EB;
  color: #ffffff;
  border-color: #4758EB;
}

.actionSelect {
  width: 200px;
  flex: 1;
  min-width: 150px;
}

.applyButton {
  background-color: #5CD685;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: all 0.2s ease;
  margin-left: 15px;
  white-space: nowrap;
}

.applyButton:hover {
  background-color: #94D1A8 !important;
}

.applyButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loadingOverlay p {
  margin-top: 10px;
  font-size: 16px;
  color: #4758EB;
}

.resultSummary {
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.failureTable {
  margin-top: 20px;
  overflow-x: auto; /* Enable horizontal scrolling for tables on smaller screens */
}

.failureTable table {
  width: 100%;
  border-collapse: collapse;
}

.failureTable th, .failureTable td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  vertical-align: top; /* Ensure text doesn't overlap with images */
}

.failureTable th {
  background-color: #4758EB;
  color: #ffffff;
}

.failureTable img {
  width: 100px;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  object-fit: contain;
}

.failureTable img:hover {
  transform: scale(1.2);
  transition: transform 0.2s ease-in-out;
}

.failureTable a {
  color: #4758EB;
  text-decoration: none;
}

.failureTable a:hover {
  text-decoration: underline;
}

.formatAndButton {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .formatAndButton {
    flex-direction: column;
    align-items: stretch;
  }

  .applyButton {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
  }

  .scopeGroup {
    flex-direction: column;
  }

  .scopeButton {
    width: 100%;
  }
}

.imageContainer {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 4px;
}

.imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.inputGroup {
    display: flex;
    gap: 10px; /* Adjust spacing between inputs as needed */
    margin-bottom: 10px; /* Optional: space below the input group */
  }
  
  /* Style for each dimension input */
  .dimensionInput {
    flex: 1; /* Allows inputs to grow equally within the container */
    padding: 8px 12px; /* Adjust padding to match existing inputs */
    border: 1px solid #ccc; /* Match existing border styles */
    border-radius: 4px; /* Match existing border radius */
    font-size: 14px; /* Match existing font size */
    /* Add any other existing input styles here */
  }
  
  /* Optional: Add focus styles for better UX */
  .dimensionInput:focus {
    outline: none;
    border-color: #4758EB; /* Highlight border on focus */
    box-shadow: 0 0 5px rgba(102, 175, 233, 0.6); /* Optional: add a subtle shadow */
  }
  
  /* Adjust the container holding inputs and button */
  .dimensionsAndButton {
    display: flex;
    align-items: center;
    gap: 20px; /* Space between input group and button */
  }
  
  