/* src/popups/css/PopupImportDefaultPromptPdp.module.css */

/* Overall overlay */
.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

/* Popup content */
.popupContent {
    background: #fff;
    width: 600px;
    max-height: 80vh;
    overflow-y: auto;
    border-radius: 8px;
    padding: 20px;
    position: relative;
}

/* Header with title + close button */
.popupHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.closeButton {
    background: none;
    border: none;
    cursor: pointer;
    color: #333;
    transition: color 0.3s;
}

.closeButton:hover {
    color: #4758EB; /* Match hover color from PromptBuilderPdp */
}

/* Body area */
.popupBody {
    padding: 10px 0;
}

/* Loading container */
.loadingContainer {
    display: flex;
    align-items: center;
    gap: 8px;
}

.loadingContainer p {
    margin: 0;
    font-weight: bold;
    color: #4758EB; /* Consistent color */
}

/* Errors, warnings, etc */
.errorMessage {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 8px;
}

/* List items for prompts */
.promptItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ececec;
    padding: 8px;
    border-radius: 4px;
    margin-bottom: 4px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
}

.promptItem:hover {
    background-color: #f1f1f1;
}

.selected {
    border-color: #4758EB; /* Highlight selected item */
    background-color: #e0e1eb; /* Subtle background color */
}

.promptName {
    font-weight: 500;
    color: #292B3D;
}

/* Action buttons on each item */
.promptActions {
    display: flex;
    gap: 8px;
}

/* Reuse button styles from PromptBuilderPdp */
.actionButton {
    background-color: #d0d0d0;
    color: #ffffff;
    border: none;
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
}

.actionButton:hover {
    background-color: #7c7c7c;
}

/* Specific button styles */
.editButton {
    background-color: #4758EB;
}

.editButton:hover {
    background-color: #3947c2;
}

.deleteButton {
    background-color: #EB4755;
}

.deleteButton:hover {
    background-color: #E0858C;
}

.importButton {
    background-color: #5CD685;
}

.importButton:hover {
    background-color: #94D1A8 !important;
}

/* Admin Create new button */
.newPromptButton {
    margin-top: 10px;
    background-color: #5CD685;
    color: #fff;
    border: none;
    padding: 10px 16px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    gap: 5px;
    align-items: center;
    transition: background-color 0.3s;
}

.newPromptButton:hover {
    background-color: #94D1A8;
}

/* No prompts */
.noPrompts {
    font-style: italic;
    color: #999;
}

/* Admin Panel for editing/creating */
.adminPanel {
    padding: 10px 0;
}

.fieldLabel {
    display: block;
    margin-top: 8px;
    margin-bottom: 2px;
    font-weight: 500;
    color: #292B3D;
}

.textInput {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.textArea {
    width: 100%;
    height: 120px;
    padding: 8px;
    box-sizing: border-box;
    resize: vertical;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.saveButton {
    margin-top: 10px;
    background-color: #4758EB;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.saveButton:hover {
    background-color: #3947c2;
}

/* Back button */
.backButton {
    background: none;
    border: none;
    color: #4758EB; /* Consistent color */
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    margin-bottom: 10px;
    font-weight: 500;
    transition: color 0.3s;
}

.backButton:hover {
    color: #3947c2;
}
