/* PopupRowDataPdp.module.css */

/* Overlay: full screen, centered */
.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3000; /* higher than main content */
    font-family: 'Lexend Deca', sans-serif;
}

/* Popup Content: dual column layout */
.popupContentDual {
    background-color: #ffffff;
    width: 90%;
    max-width: 1920px;
    max-height: 90vh; /* allows for internal scrolling */
    border-radius: 8px;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden; /* prevent outer scrolling */
}

/* Close Button (circular style) */
.closeButton {
    position: absolute;
    top: 20px;
    right: 20px;
    background: #E0858C;
    color: #ffffff;
    border: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    cursor: pointer;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
}

.closeButton:hover {
    background-color: #EB4755 !important;
}

/* Header Section */
.popupHeader {
    padding: 1rem;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    color: #292B3D;
    flex: 0 0 auto; /* Fixed height */
}

.popupPreview {
    display: flex;
    align-items: center;
    width: 100%;
}

.popupPreviewImage {
    width: 100px;
    height: 100px;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popupPreviewImage img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 4px;
}

.noImage {
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
    color: #888;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    text-align: center;
    padding: 0.5rem;
}

.popupPreviewDetails {
    display: flex;
    flex-direction: column;
}

.popupPreviewDetails p {
    margin: 4px 0;
    font-size: 0.95rem;
    color: #333;
}

/* Dual Container: holds left and right columns */
.dualContainer {
    flex: 1 1 0; /* Take up remaining space */
    display: flex;
    flex-direction: row;
    overflow: hidden; /* prevent overall scroll */
}

/* LEFT SIDE */
.leftSide {
    flex: 1;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #ddd;
    padding: 1rem;
    box-sizing: border-box;
    background-color: #ffffff;
}

.leftSide h3 {
    margin-bottom: 0.5rem;
    color: #292B3D;
    font-size: 1.1rem;
    flex: 0 0 auto;
}

.leftContent {
    flex: 1 1 0;
    overflow-y: auto;
    padding-right: 0.5rem;
    min-height: 0; /* for proper flexbox scrolling */
}

/* RIGHT SIDE */
.rightSide {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    box-sizing: border-box;
    background-color: #ffffff;
}

.rightSide h3 {
    margin-bottom: 0.5rem;
    color: #292B3D;
    font-size: 1.1rem;
    flex: 0 0 auto;
}

.rightContent {
    flex: 1 1 0;
    overflow-y: auto;
    padding-right: 0.5rem;
    min-height: 0; /* for proper flexbox scrolling */
}

/* Row Item: key-value pair */
.rowItem {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.rowItemKey {
    font-weight: 600;
    color: #292B3D;
    margin-bottom: 0.25rem;
    display: flex;
    align-items: center;
    line-height: 1.3;
}

.rowItemValue {
    display: flex; 
    flex-direction: column;
    align-items: flex-start;
    font-size: 0.95rem;
    line-height: 1.4;
}

/* Column Icons */
.columnIcon {
    margin-left: 8px;
    color: #292B3D;
    font-size: 0.9rem;
}

/* Row Images */
.rowImage {
    display: block; 
    max-width: 100px; 
    max-height: 100px; 
    object-fit: contain; 
    border-radius: 4px;
    margin: 0.5rem 0;
}

/* Unsaved Changes Message */
.popupUnsaved {
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff3cd;
    color: #856404;
    padding: 0.75rem 1.25rem;
    border: 1px solid #ffeeba;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.2);
    z-index: 4000;
    font-size: 0.95rem;
}

/* Save Success Message */
.popupSuccess {
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #d4edda;
    color: #155724;
    padding: 0.75rem 1.25rem;
    border: 1px solid #c3e6cb;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.2);
    z-index: 4000;
    font-size: 0.95rem;
}

/* Sticky Save Button Container */
.stickySaveContainer {
    position: sticky;
    bottom: 0;
    background-color: #ffffff;
    padding: 1rem;
    border-top: 1px solid #ddd;
    text-align: right;
    flex: 0 0 auto;
}

/* Save Button */
.saveButton {
    background-color: #4758EB;
    color: #ffffff;
    border: none;
    padding: 0.6rem 1.4rem;
    border-radius: 20px;
    cursor: pointer;
    font-size: 1rem;
    font-family: 'Lexend Deca', sans-serif;
    transition: background-color 0.3s ease, box-shadow 0.3s;
}

.saveButton:hover {
    background-color: #848EE0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.saveButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Responsive Design */
@media (max-width: 768px) {
    .dualContainer {
        flex-direction: column;
    }

    .leftSide {
        border-right: none;
        border-bottom: 1px solid #ddd;
    }

    .rightSide {
        padding-top: 1rem;
    }
}

/* Optional: Smooth Scrolling and Custom Scrollbars */
.leftContent,
.rightContent {
    scroll-behavior: smooth;
}

.leftContent::-webkit-scrollbar,
.rightContent::-webkit-scrollbar {
    width: 6px;
}

.leftContent::-webkit-scrollbar-track,
.rightContent::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.leftContent::-webkit-scrollbar-thumb,
.rightContent::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;
}

.leftContent::-webkit-scrollbar-thumb:hover,
.rightContent::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
}


/* Add to PopupRowDataPdp.module.css */

/* Global Toggle Container */
.globalToggle {
    margin-top: 50px !important;
    display: flex;
    align-items: center;
    width: 210px;
    margin-left: auto; /* Push to the right */
}

/* Toggle Label */
.toggleLabel {
    margin-right: 0.5rem;
    font-size: 0.95rem;
    color: #292B3D;
}




/* Add to PopupRowDataPdp.module.css */

/* Plain Text Editor */
.plainTextEditor {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 0.95rem;
    min-height: 100px; /* Ensure sufficient height */
    box-sizing: border-box;
    background-color: #f9f9f9; /* Light background for readability */
    color: #333; /* Dark text for contrast */
    
}


/* Wrap row header in a flex container so label is on left & toggle is on right. */

  .rowItemLabel {
    display: inline-flex;
    align-items: center;
    gap: 4px; /* space between text and icon */
  }
  
  .toggleButton {
    background: none;
    border: none;
    font-size: 1.2rem;
    color: #666;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
  }
  
  .toggleButton:hover {
    cursor: pointer;
    color: #4758EB;
    background: #ffffff !important;
    transition: color 0.3s;
}

.toggleButtonActive {
    color: #4758EB;  /* blue color when activated */
  }


.rowItemHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
  }
  
  .editorToggle {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .editorToggleLabel {
    font-size: 0.9rem;
    color: #555;
  }
  

  