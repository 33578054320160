@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@400;600&display=swap');

.container {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  font-family: 'Lexend Deca', sans-serif;
  color: #292B3D;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.container h2 {
  color: #4758EB;
  margin-bottom: 10px;
}

.container p {
  font-size: 16px;
  line-height: 1.5;
}
