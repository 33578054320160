/* Popup overlay */
.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Popup content */
  .popupContent {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 800px;
    text-align: center;
    position: relative;
  }
  
  /* Popup header */
  .popupHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Close button */
  .closeButton {
    background-color: #d0d0d0;
    color: #ffffff;
    border: none;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .closeButton:hover {
    background-color: #7c7c7c;
  }
  
  /* Dropzone styling */
  .dropzone {
    width: 100%;
    min-height: 300px;
    border: 2px dashed #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ccc;
    background-color: #f9f9f9;
    margin-bottom: 15px;
    transition: border-color 0.3s, background-color 0.3s;
    font-family: 'Lexend Deca', sans-serif;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
  }
  
  .dropzoneActive {
    border-color: #4758EB;
    background-color: rgba(108, 99, 255, 0.1);
  }
  
  /* Dropzone content (cloud, text, and button) */
  .dropzoneContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .dropzoneIcon {
    font-size: 48px;
    color: #4758EB;
    margin-top: 20px;
  }
  
  .dropzoneText {
    font-size: 16px;
    color: #888;
    margin-bottom: 10px;
  }
  
  .browseButton {
    background-color: #4758EB;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 20px;
  }
  
  .browseButton:hover {
    background-color: #3949ab;
  }
  

  /* Styling for the uploaded image */
.uploadedImage {
    max-width: 600px; /* Ensure the image fits within the dropzone width */
    max-height: 250px; /* Constrain the height to a reasonable size */
    object-fit: contain; /* Ensure the image scales without being distorted */
    margin-top: 10px;
    border-radius: 8px; /* Optional: if you want rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add shadow for effect */
  }
  .buttonContainer {
    display: flex;
    justify-content: flex-end; /* Align button to the right */
    align-items: center; /* Align items vertically */
    margin-top: 20px; /* Add space between the dropzone and button */
    position: relative; /* Make sure the button stays in place */
    min-height: 50px; /* Ensure the container height stays fixed */
  }
  
  .successMessage {
    color: #5CD685;
    font-size: 14px;
    margin-right: auto; /* Ensure the message appears on the left side */
  }
  
  .uploadButton {
    background-color: #5CD685; /* Lighter green */
    color: #ffffff; /* White icon color */
    border: none;
    padding: 10px!important;
    border-radius: 50%; /* Make the button circular */
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px!important;
    height: 40px!important;
    position: relative; /* Stay within the flex container */
  }
  
  .uploadButton:disabled {
    background-color: #ccc; /* Gray color for disabled state */
    cursor: not-allowed;
  }
  
  .uploadButton:hover {
    background-color: #94D1A8 !important; /* Darker green on hover */
  }
  
  .uploadButton:disabled:hover {
    background-color: #ccc !important; /* Ensure the hover color stays gray for disabled */
  }