.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
    position: relative;
}

.button {
    background-color: #4CAF50; /* Green */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px 0;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #45a049;
}

.newCombinationNameInput {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 10px;
    width: 100%;
}

.closeButton {
    background: transparent;
    border: none;
    color: red;
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
    transition: transform 0.2s, background-color 0.2s;
}

.closeButton:hover {
    color: red;
    transform: scale(1.1);
    background: transparent !important;
}

.error {
    color: red;
    margin-top: 10px;
}

.optionsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.buttonGroup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
