.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popupContainer {
  background-color: #ffffff;
  padding: 20px 30px;
  border-radius: 10px;
  width: 90%;
  max-width: 800px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  position: relative;
  max-height: 90vh;
  overflow-y: auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header h2 {
  margin: 0;
  font-family: 'DM Sans', sans-serif;
  color: #292B3D;
}

.section {
  margin-bottom: 30px;
}

.section h3 {
  margin-bottom: 15px;
  font-family: 'DM Sans', sans-serif;
  color: #292B3D;
  font-size: 18px;
}

.updateFeedContainer {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.updateButton {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.updateButton:hover {
  background-color: #848EE0 !important;
}

.updateButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.lastUpdated {
  font-family: 'Lexend Deca', sans-serif;
  color: #292B3D;
  font-size: 14px;
}

.successMessage {
  margin-top: 10px;
  color: #28a745;
  font-family: 'Lexend Deca', sans-serif;
}

.errorMessage {
  margin-top: 10px;
  color: #dc3545;
  font-family: 'Lexend Deca', sans-serif;
}

.imagePreviewContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 20px; /* This sets the gap between rows only */
  column-gap: 20px; /* This sets the gap between columns only */
}

.imageItem {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding-bottom: 15px;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  height: 190px;
}

.previewImage {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  transition: transform 0.3s;
}

.previewImage:hover {
  transform: scale(1.05);
}

.imageLabel {
  margin-top: 8px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  color: #292B3D;
  text-align: center;
  word-wrap: break-word;
  max-width: 200px;
}

.buttonContainer {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}

.actionButton {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 13px;
  transition: background-color 0.3s;
}

.actionButton:hover {
  background-color: #848EE0 !important;
}

.actionButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
