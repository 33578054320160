/* src/components/popups/css/PopupBackground.module.css */

/* CSS Variables for Consistency */
:root {
  --popup-overlay-bg: rgba(41, 43, 61, 0.8);
  --popup-content-bg: #ffffff;
  --primary-color: #4758eb;
  --primary-hover: #3949ab;
  --danger-color: #eb4755;
  --danger-hover: #d43c3a;
  --success-color: #28a745;
  --warning-color: #ffc107;
  --text-color: #292b3d;
  --secondary-text-color: #666666;
  --border-color: #ccc;
  --legend-added-bg: rgba(0, 128, 0, 0.3);
  --legend-cropped-bg: rgba(255, 0, 0, 0.3);
  --canvas-bg: #f9f9f9;
  --overlay-bg: rgba(255, 255, 255, 0.7);
  --processing-text-color: #4758eb;
}

/* Popup Overlay */
.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--popup-overlay-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 10px;
  box-sizing: border-box;
}

/* Popup Content */
.popupContent {
  background-color: var(--popup-content-bg);
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 1200px; /* Increased max-width for better side-by-side display */
  position: relative;
  overflow-y: auto;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Popup Header */
.popupHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff; /* Replace with your desired color */
}

.popupHeader h2 {
  font-family: 'DM Serif Text', serif;
  font-size: 24px;
  color: var(--text-color);
  margin: 0;
}

.closeButton {
  background: none;
  border: none;
  font-size: 28px;
  cursor: pointer;
  color: var(--text-color);
  transition: color 0.3s;
}

.closeButton:hover {
  color: var(--danger-color);
}

/* Configuration Section */
.configurationSection {
  margin-bottom: 30px;
}

.dropdownRow {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.dropdownSection {
  flex: 1;
  position: relative;
  min-width: 150px;
}

.dropdownHeader {
  background-color: #e0e1eb;
  border: none;
  color: var(--text-color);
  padding: 10px 20px;
  height: 40px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 20px;
  font-size: 14px;
  transition: background-color 0.3s ease;
  width: 100%;
  text-align: center;
}

.dropdownHeader:hover {
  background-color: var(--primary-color); /* Change background color on hover */
  color: #ffffff; /* Change text color to white on hover */
}

.dropdownContent {
  position: absolute;
  top: 50px;
  left: 0;
  background-color: var(--popup-content-bg);
  padding: 15px;
  border-radius: 16px;
  margin-top: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  z-index: 999;
}

.option {
  margin: 5px 0;
  padding: 10px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: background-color 0.2s;
}

.option:hover {
  background-color: #f0f0f0;
}

.option.active {
  background-color: var(--primary-color);
  color: #ffffff;
}

.colorPicker {
  margin-top: 15px;
  width: 89%;
}

.option span {
  font-size: 16px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.submitButton {
  background-color: var(--primary-color);
  color: #ffffff;
  border: none;
  padding: 12px 24px;
  border-radius: 24px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.2s;
  display: flex;
  align-items: center;
  gap: 10px;
}

.submitButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.submitButton:hover:not(:disabled) {
  background-color: var(--primary-hover);
  transform: translateY(-2px);
}

.submitButton:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(71, 88, 235, 0.4);
}

/* Images Container */
.imagesContainer {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 30px;
  flex-wrap: wrap;
  align-items: stretch; /* Ensure containers stretch equally */
}

.sideBySideImageContainer {
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.sideBySideImageContainer .imageLabel {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: 'Lexend Deca', sans-serif;
  color: var(--text-color);
}

.sideBySideImage {
  min-height: 40%; /* Adjust as needed */
  max-height: 80%; /* Adjust as needed */
  min-width: 40%; /* Adjust as needed */
  max-width: 80%; /* Adjust as needed */
  object-fit: contain;
  border-radius: 10px;
  /* Add shadow to images */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.imageWrapper {
  position: relative;
  flex: 1; /* Ensure the wrapper takes up available space */
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(71, 88, 235, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.loader {
  width: 50px;
  height: 50px;
  border: 5px solid #ffffff;
  border-top: 5px solid var(--primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Keyframes for the loader animation */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .dropdownRow {
    flex-direction: column;
  }

  .imagesContainer {
    flex-direction: column;
    align-items: center;
  }

  .sideBySideImage {
    max-width: 80%;
  }
}

.dropdownHeaderContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.colorIndicator {
  width: 20px;
  height: 20px;
  margin-left: 8px;
  border: 1px solid #000;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
}

.checkerboardBackground {
  background-color: #fff;
  background-image:
    linear-gradient(45deg, #ccc 25%, transparent 25%),
    linear-gradient(-45deg, #ccc 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #ccc 75%),
    linear-gradient(-45deg, transparent 75%, #ccc 75%);
  background-size: 8px 8px;
  background-position: 0 0, 0 4px, 4px -4px, -4px 0px;
}

.option {
  margin: 5px 0;
  padding: 10px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: background-color 0.2s;
}

.option:hover {
  background-color: #f0f0f0;
}

.option.active {
  background-color: var(--primary-color);
  color: #ffffff;
}

.sizeInputContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
}

.sizeInput {
  width: 60px;
  padding: 5px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.sizeInput:disabled {
  background-color: #e0e0e0;
}

.sizeInputContainer span {
  font-size: 18px;
}

.subSection {
  margin-bottom: 20px;
}

.subSectionTitle {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
  color: var(--text-color);
  display: flex;
  align-items: center;
  position: relative;
}

.radioGroup {
  display: flex;
  gap: 0px; /* Reduced gap for better spacing */
  flex-wrap: wrap; /* Allow wrapping if space is limited */
}

.customRadioOption {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  position: relative;
  font-family: 'Lexend Deca', sans-serif;
  flex: 1 1 auto; /* Allow flexible width */
}

.customRadioOption input[type='radio'] {
  opacity: 0;
  position: absolute;
  left: -9999px;
}

.customRadio {
  width: 16px;
  height: 16px;
  border: 2px solid var(--primary-color);
  border-radius: 50%;
  display: inline-block;
  position: relative;
  transition: background-color 0.3s, border-color 0.3s;
}

.customRadioOption input[type='radio']:checked + .customRadio {
  background-color: var(--primary-color);
}

.customRadioOption input[type='radio']:focus + .customRadio {
  box-shadow: 0 0 0 3px rgba(71, 88, 235, 0.3);
}

.customRadioOption span:last-child {
  margin-left: 0px;
}

/* Hover and Active States */
.customRadioOption:hover .customRadio {
  border-color: #3949ab;
}

.customRadioOption:hover span {
  color: #3949ab;
}

/* Padding Input Styles */
.paddingInputContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.paddingInput {
  width: 60px;
  padding: 5px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.paddingUnitSelect {
  padding: 5px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

/* Tooltip Styles */
.tooltipContainer {
  position: relative;
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
}

.infoIcon {
  color: var(--primary-color);
  transition: color 0.3s;
}

.infoIcon:hover {
  color: var(--danger-color);
}

.tooltipText {
  visibility: hidden;
  width: 220px;
  background-color: #555;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1001;
  bottom: 125%; /* Position above the icon */
  left: 50%;
  margin-left: -110px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltipText::after {
  content: '';
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltipContainer:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}

/* New styles for error messages */
.errorMessageContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #fdecea; /* Light red background */
  color: var(--danger-color);
  padding: 10px 15px;
  border: 1px solid #f5c6cb;
  border-radius: 8px;
  margin-top: 10px;
  font-family: 'Lexend Deca', sans-serif;
}

.successMessageContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #e6ffed; /* Light green background */
  color: var(--success-color);
  padding: 10px 15px;
  border: 1px solid #c3e6cb;
  border-radius: 8px;
  margin-top: 10px;
  font-family: 'Lexend Deca', sans-serif;
}

.errorMessage {
  color: var(--danger-color); /* Error color */
  font-size: 12px;
  margin-top: 5px;
}

/* Additional Styles for Image Upload */
.imageUpload {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.loaderContainer {
  margin-top: 10px;
}

.backgroundImagePreview {
  margin-top: 10px;
}

.previewImage {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
}

/* Ensuring the color indicator doesn't interfere */
.colorIndicator::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

/* Stack radio buttons vertically */
.verticalRadioGroup {
  display: flex;
  flex-direction: column;
}

.customRadioOption {
  margin-bottom: 10px; /* Space between radio options */
}

/* Switch Styles */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Effect Option Container */
.effectOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

/* Remove Text Modes */
.removeTextModes {
  margin-left: 20px;
  margin-top: 10px;
}

/* Cost Container */
.costContainer {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  color: var(--text-color);
  margin-top: 10px;
}

/* Messages Container */
.messagesContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .dropdownRow {
    flex-direction: column;
  }

  .imagesContainer {
    flex-direction: column;
    align-items: center;
  }

  .sideBySideImage {
    max-width: 80%;
  }
}

@media (max-width: 768px) {
  .dropdownRow {
    flex-direction: column;
  }

  .imagesContainer {
    flex-direction: column;
    align-items: center;
  }

  .sideBySideImage {
    max-width: 80%;
  }
}
