/* src/popups/css/PopupTextPublish.module.css */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popupContainer {
  background-color: #ffffff;
  padding: 25px 35px;
  border-radius: 12px;
  width: 95%;
  max-width: 650px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  position: relative;
  max-height: 90vh;
  overflow-y: auto;
  animation: slideDown 0.3s ease-in-out;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.header h2 {
  margin: 0;
  font-family: 'DM Sans', sans-serif;
  color: #2c3e50;
  font-size: 24px;
}

.closeButton {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #7f8c8d;
  transition: color 0.2s;
}

.closeButton:hover {
  color: #2c3e50;
}

.message {
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
}

.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.error {
  background-color: #f8d7da;
  color: #EB4755;
  border: 1px solid #f5c6cb;
}

.lastUpdatedSection {
  font-family: 'Lexend Deca', sans-serif;
  color: #34495e;
  font-size: 16px;
  margin-bottom: 20px;
}

.controlsSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 25px;
}

.toggleContainer {
  display: flex;
  align-items: center;
}

.toggleLabel {
  margin-right: 10px;
  font-weight: 500;
  font-family: 'Lexend Deca', sans-serif;
  color: #2c3e50;
  font-size: 14px;
}

.toggleInput {
  width: 24px;
  height: 24px;
  cursor: pointer;
  accent-color: #4758EB !important;
  transition: transform 0.2s;
}

.toggleInput:focus {
  outline: 2px solid #E0E1EB !important;
  outline-offset: 2px;
}

.publishButton {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.1s;
  display: flex;
  align-items: center;
  gap: 8px;
}

.publishButton:hover {
  background-color: #555EAA !important;
}

.publishButton:active {
  transform: scale(0.98);
}

.publishButton:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
}

.buttonIcon {
  display: inline-block;
  vertical-align: middle;
}

.divider {
  border: none;
  border-top: 1px solid #ecf0f1;
  margin: 0 0 25px 0;
}

.section {
  margin-bottom: 35px;
}

.section h3 {
  font-family: 'Lexend Deca', sans-serif;
  color: #2c3e50;
  font-size: 20px;
  margin-bottom: 15px;
}

.feedPreviewContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.feedItem {
  background-color: #E0E1EB;
  border-radius: 10px;
  padding: 18px 22px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: background-color 0.3s;
}

.feedInfo {
  margin-bottom: 12px;
}

.feedInfo h4 {
  margin: 0 0 6px 0;
  font-family: 'Lexend Deca', sans-serif;
  color: #2c3e50;
  font-size: 18px;
}

.feedInfo p {
  margin: 0;
  font-family: 'Lexend Deca', sans-serif;
  color: #7f8c8d;
  font-size: 14px;
}

.buttonGroup {
  display: flex;
  gap: 12px;
}

.actionButton {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: background-color 0.3s, transform 0.1s;
  display: flex;
  align-items: center;
  gap: 6px;
}

.actionButton:hover {
  background-color: #555EAA !important;
}

.actionButton:active {
  transform: scale(0.98);
}

.actionButton:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
}

.toggleContainer {
  display: flex;
  align-items: center;
}

.toggleLabel {
  margin-right: 10px;
  font-weight: 500;
  font-family: 'Lexend Deca', sans-serif;
  color: #2c3e50;
  font-size: 14px;
}

.toggleInput {
  width: 24px;
  height: 24px;
  cursor: pointer;
  accent-color: #2980b9;
  transition: transform 0.2s;
}

.toggleInput:focus {
  outline: 2px solid #3498db;
  outline-offset: 2px;
}

/* Responsive Design */
@media (max-width: 480px) {
  .popupContainer {
    padding: 20px 25px;
  }

  .header h2 {
    font-size: 20px;
  }

  .publishButton {
    font-size: 14px;
    padding: 8px 16px;
  }

  .section h3 {
    font-size: 18px;
  }

  .feedInfo h4 {
    font-size: 16px;
  }

  .feedInfo p {
    font-size: 13px;
  }

  .actionButton {
    font-size: 13px;
    padding: 6px 12px;
  }

  .controlsSection {
    flex-direction: column;
    align-items: stretch;
  }

  .publishButton {
    width: 100%;
    justify-content: center;
  }
}
