/* ================================
   Container, Layout, Panes
   (same as your original)
================================ */

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  overflow: hidden; /* Prevent overall page scroll */
}

/* Left Pane */
.leftPane {
  width: 50%;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  box-sizing: border-box;
}

/* Scrollable content in left pane */
.leftPaneContent {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
}

/* Right Pane */
.rightPane {
  width: 50%;
  overflow-y: auto;
  padding: 20px;
  background-color: #ffffff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

/* Top Row: Back Button + Title */
.topRow {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.backButton {
  background-color: #d0d0d0;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}
.backButton:hover {
  background-color: #7c7c7c;
}

.pageTitle {
  font-family: 'DM Serif Text', serif;
  font-size: 24px;
  color: #292B3D;
  margin: 0;
}

/* Row containing two columns */
.dropdownRow {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.clientColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.promptColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Prompt dropdown and buttons side by side */
.promptAndButtons {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  margin-top: auto;
}

.promptDropdownContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.iconButtons {
  display: flex;
  flex-direction: row;
}

.dropdownLabel {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  color: #333333;
  margin-bottom: 4px;
}

/* Error Message */
.errorMessage {
  margin-top: 10px;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  padding: 10px;
  border-radius: 8px;
  font-family: 'Lexend Deca', sans-serif;
}

/* Status bar (for success / error messages) */
.statusBar {
  padding: 10px 20px;
  margin: 10px 0;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: opacity 0.5s ease-in-out;
}
.success {
  background-color: #d4edda;
  color: #155724;
}
.error {
  background-color: #f8d7da;
  color: #721c24;
}

/* Buttons */
.addButton {
  background-color: #5CD685;
  color: #ffffff;
  border: none;
  padding: 10px !important;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px !important;
  height: 30px !important;
}
.addButton:hover {
  background-color: #94D1A8 !important;
}
.addButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.removeButton {
  background-color: #EB4755;
  color: #ffffff !important;
  border: none;
  padding: 10px !important;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px !important;
  height: 30px !important;
}
.removeButton:hover {
  background-color: #E0858C !important;
}
.removeButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.importButton {
  background-color: #d0d0d0;
  color: #ffffff;
  border: none;
  padding: 10px !important;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px !important;
  height: 30px !important;
}
.importButton:hover {
  background-color: #7c7c7c !important;
}
.importButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Save button */
.saveButton {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  border-radius: 40px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
}
.saveButton:hover {
  background-color: #3947c2;
}

/* Sticky unsaved changes bar at bottom of leftPane */
.stickyUnsavedBar {
  position: sticky;
  bottom: 0;
  left: 0;
  padding: 10px;
  background-color: #ffdca8; /* light highlight color */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ccc;
}

.stickyUnsavedText {
  color: #663c00;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
}

/* Loading container */
.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.loadingContainer p {
  margin-top: 10px;
  color: #4758EB;
  font-weight: bold;
}

/* Prompt containers (system, image, instruction) */
.promptContainer {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 10px;
  overflow: hidden;
}
.promptHeader {
  background-color: #EAEAEA;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.promptHeaderTitle {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
}
.promptHeaderActions {
  display: flex;
  gap: 8px;
}
.promptBody {
  padding: 8px 12px;
  padding-right: 30px;
  background-color: #fff;
}
.textAreaPrompt {
  width: 100%;
  min-height: 100px;
  resize: vertical;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  font-family: sans-serif;
}

.textAreaFewShotPrompt {
  width: 100%;
  min-height: 60px;
  resize: vertical;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  padding-top: 5px;
  padding-left: 5px;
  font-family: sans-serif;
}

/* Section label for multi-select, etc. */
.section {
  margin-bottom: 20px;
}
.sectionLabel {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  color: #333333;
  margin-bottom: 4px;
}
.columnsMultiSelect {
  margin-bottom: 8px;
}

/* =============== FEW SHOT SECTION =============== */

/* Container that wraps the entire few-shot area */
.fewShotSection {
  margin-top: 40px;
  padding: 20px;
  background-color: #fcfcfc;
  border: 1px solid #ececec;
  border-radius: 8px;
}

/* Section header: Title + "Add Example" button */
.fewShotSectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.fewShotSectionTitle {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  color: #292b3d;
}

/* Optional short description/instructions under the header */
.fewShotSectionDescription {
  font-size: 14px;
  color: #555;
  margin-bottom: 16px;
  font-family: 'Lexend Deca', sans-serif;
  line-height: 1.4;
}

/* Add Example button in the top section */
.addFewShotButton {
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: #5cd685;
  color: #ffffff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: 'Lexend Deca', sans-serif;
}

.addFewShotButton:hover {
  background-color: #48b76b !important;
}

/* List of existing few-shot prompts */
.fewShotList {
  display: flex;
  flex-direction: column;
  gap: 20px; /* space between each prompt container */
}

/* Each card-like container for an individual "Few Shot Prompt" */
.fewShotPromptContainer {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.06);
  overflow: hidden;
}

/* Header (title + remove button) */
.fewShotPromptHeader {
  background-color: #EAEAEA;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer; /* [NEW: Make entire header clickable for expand/collapse] */
}

.fewShotPromptTitle {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: #292b3d;
  display: flex;
  align-items: center;
  gap: 8px;
}

.fewShotPromptActions {
  display: flex;
  gap: 8px;
}

/* The remove button specifically for few-shot prompts */
.removeFewShotButton {
  background-color: #eb4755;
  color: #ffffff !important;
  border: none;
  padding: 6px 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.removeFewShotButton:hover {
  background-color: #c73a46 !important;
}

/* Body content inside a single few-shot card */
.fewShotPromptBody {
  display: flex;
  flex-direction: column;
  gap: 12px; /* vertical spacing */
  padding: 15px 20px; /* [NEW: Moved from container to body for better spacing] */
}

/* Row that holds ID input + Import button + Toggle RTE button */
.fewShotPromptRow > * {
  flex: none;
}

/* Group for ID label + input */
.fewShotPromptButtons {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Optional: Adjust the width of the ID input to take more space */
.fewShotPromptIdGroup {
  flex: 1; /* Allow it to grow and take available space */
  display: flex;
  align-items: center;
  gap: 6px;
}

.fewShotPromptIdGroup label {
  font-size: 14px;
  color: #333;
  font-family: 'Lexend Deca', sans-serif;
}

.fewShotPromptInput {
  padding: 6px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: sans-serif;
  font-size: 14px;
  min-width: 100px;
}

/* Import button in each row */
.importFewShotButton {
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: #d0d0d0;
  color: #fff;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  transition: background-color 0.3s;
}

.importFewShotButton:hover {
  background-color: #7c7c7c !important;
}

/* Toggle button for Rich Text */
.richTextToggleButton {
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: #d0d0d0;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 6px 10px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  transition: background-color 0.3s;
}

.richTextToggleButton:hover {
  background-color: #7c7c7c;
}

/* A scroll box to show a small preview of the data */
.fewShotPromptScrollBox {
  max-height: 160px; /* Limit vertical space */
  overflow-y: auto;
  background-color: #f9f9f9;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: sans-serif;
  font-size: 14px;
  color: #333;
  margin-bottom: 10px; /* some spacing below */
}

/* Grid for base columns (meta_title, meta_description, etc.) */
.fewShotColumnsGrid {
  display: flex;
  flex-direction: column;
  gap: 20px; /* or whatever spacing you prefer between editors */
}

@media (max-width: 800px) {
  .fewShotColumnsGrid {
    grid-template-columns: 1fr; /* single column on smaller screens */
  }
}

.fewShotColumnField {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.fewShotColumnLabel {
  font-size: 13px;
  font-weight: 500;
  color: #444;
  font-family: 'Lexend Deca', sans-serif;
}




/* If toggled to Rich Text, we simply show a placeholder. 
 Replace with a real WYSIWYG editor if you want. */
.richTextEditorPlaceholder {
  border: 1px dashed #aaa;
  padding: 8px;
  min-height: 80px;
  border-radius: 4px;
  background-color: #fcfcfc;
}

.richTextEditorQuill {
  /* Make the editor’s text area the same general size as your textarea. */
  min-height: 100px;
  margin-bottom: 0px;
}

/* Optional: if you want to adjust the toolbar styling or spacing */
.ql-toolbar.ql-snow {
  border-radius: 4px 4px 0 0;
}
.ql-container.ql-snow {
  border-radius: 0 0 4px 4px;
}

/* Toggle Switch Styles */
.toggleSwitch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.toggleSwitch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.toggleSwitch input:checked + .slider {
  background-color: #4758EB;
}

.toggleSwitch input:checked + .slider:before {
  transform: translateX(26px);
}

/* Adjust the fewShotPromptRow to align items */
.fewShotPromptRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  justify-content: space-between; /* Push buttons to the right */
}

/* New container for buttons to align them to the right */
.fewShotPromptButtons {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Optional: Style for the fewShotPromptRow to handle space */
.fewShotPromptRow > * {
  flex: none;
}

/* A small grid to show label/value pairs for each column. */
.previewTable {
  display: grid;
  grid-template-columns: auto 1fr; 
  /* 'auto' for label width, '1fr' for value width */
  gap: 4px 8px;
  margin-top: 4px;
}

/* Each row is displayed as "display: contents" or just separate divs. */
.previewRow {
  display: contents;
}

/* Label cell in the grid. */
.previewLabel {
  font-weight: 600;
  color: #444;
}

/* Value cell in the grid. */
.previewValue {
  color: #555;
  word-wrap: break-word;
  white-space: pre-wrap; /* handle newlines if any */
  overflow-wrap: anywhere;
}


/* Example: Similar styling to .fewShotSection but with a distinct background color. */

.auditPromptSection {
  margin-top: 40px;
  padding: 20px;
  background-color: #fff8e6; /* a soft yellowish background */
  border: 1px solid #eccb91;
  border-radius: 8px;
}

.auditPromptHeader {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 8px;
}

.auditPromptHeaderTitle {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #292b3d;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0;
}

.auditPromptBody {
  margin-top: 10px;
  margin-right: 20px;
}

.auditPromptDescription {
  font-size: 14px;
  color: #444;
  margin-bottom: 15px;
  line-height: 1.4;
  font-family: 'Lexend Deca', sans-serif;
}

.auditPromptToggleRow {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 15px;
}

.auditPromptToggleLabel {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  color: #333;
}


/* Header for Prompt Preview */
.promptPreviewHeader {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px; /* Match existing header sizes */
  font-weight: 600;
  color: #292B3D;
  margin-bottom: 16px; /* Consistent spacing */
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Container for the ID input and Generate Preview button */
.previewInputRow {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

/* Input field for entering the ID */
.previewInput {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  box-sizing: border-box;
}

/* Generate Preview Button */
.generatePreviewButton {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 10px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.generatePreviewButton:hover {
  background-color: #3947c2;
}

.generatePreviewButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Error Message for Preview Generation */
.previewErrorMessage {
  margin-bottom: 10px;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  padding: 10px;
  border-radius: 8px;
  font-family: 'Lexend Deca', sans-serif;
}

/* Container for the Generated Preview */
.generatedPreviewBox {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 12px;
  background-color: #fafafa;
  max-height: 60vh;
  overflow-y: auto;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
}

.generatedPreviewBox h3 {
  margin-top: 0;
  font-size: 18px;
  color: #292B3D;
}

/* Content inside the Generated Preview */
.generatedPreviewContent {
  white-space: pre-wrap;
  word-wrap: break-word;
  background: #fff;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
}

/* Loader for Right Pane (Optional: Reuse existing loading styles or customize) */
.rightPaneLoading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.rightPaneLoading p {
  margin-top: 10px;
  color: #4758EB;
  font-weight: bold;
  font-family: 'Lexend Deca', sans-serif;
}

.rightPaneHeader {
font-family: 'DM Serif Text', serif;
font-size: 24px;
color: #292B3D;

}

/* ===== Comparison Styles ===== */
.comparisonSection {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #ffffff;
  margin-bottom: 20px;
  overflow: hidden;
}

.comparisonHeader {
  background-color: #EAEAEA;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.comparisonHeaderTitle {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: #292B3D;
  display: flex;
  align-items: center;
  gap: 8px;
}

.richTextToggleContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Label for the Rich Text toggle in the right pane */
.richTextToggleLabelRightPane {
  font-size: 14px;
  font-weight: 500;
  color: #292B3D;
}

/* The "Import as Few Shot" button in the right pane */
.importFewShotButtonRightPane {
  background-color: #4758EB; /* Use your brand color */
  color: #ffffff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: background-color 0.3s;
}

.importFewShotButtonRightPane:hover {
  background-color: #3947c2;
}

.comparisonHeaderActions {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* or "space-between" if you prefer */
  gap: 12px; 
  padding-right: 16px; /* some spacing from the right edge */
}

.comparisonBody {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.comparisonIdLabel {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #444;
  margin-bottom: 2px;
}

/* columns_used displayed as small tags */
.columnsUsedContainer {
  background-color: #f9f9f9;
  border: 1px dashed #ccc;
  border-radius: 4px;
  padding: 8px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 13px;
  color: #555;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.columnTag {
  background-color: #e0e1eb;
  color: #333;
  border-radius: 3px;
  padding: 2px 6px;
}

/* Toggle Switch */
.toggleSwitchComparison {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.toggleSwitchComparison input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sliderComparison {
  position: absolute;
  cursor: pointer;
  top: 0; left: 0; right: 0; bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}
.sliderComparison:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}
.toggleSwitchComparison input:checked + .sliderComparison {
  background-color: #4758EB;
}
.toggleSwitchComparison input:checked + .sliderComparison:before {
  transform: translateX(26px);
}

/* Side-by-side columns */
.sideBySideContainer {
  display: flex;
  gap: 16px;
  flex-wrap: wrap; /* so it wraps on small screens */
}

.dataColumn {
  flex: 1;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 6px;
  overflow: hidden;
}

.columnHeader {
  background-color: #F0F0F0;
  padding: 8px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #444;
}

.columnContent {
  flex: 1;
  overflow-y: auto;
  padding: 8px;
}

.dataRow {
  margin-bottom: 16px;
}

.dataLabel {
  font-weight: 500;
  margin-bottom: 4px;
  font-size: 13px;
  color: #555;
}

.dataValuePlainText {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 6px;
  font-size: 13px;
  width: 100%;
  font-family: sans-serif;
  min-height: 100px;
  background-color: #fff;
  color: #333;
  box-sizing: border-box;
  resize: vertical;
}


/* Borrow Quill styling as needed */
.ql-toolbar.ql-snow {
  border-radius: 4px 4px 0 0;
}
.ql-container.ql-snow {
  border-radius: 0 0 4px 4px;
}

.itemPreviewContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin-bottom: 20px;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 12px;
}

.itemPreviewImageContainer {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  overflow: hidden;
  flex-shrink: 0;
}

.itemPreviewImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.itemPreviewDetails {
  display: flex;
  flex-direction: column;
  gap: 2px; 
  font-family: 'Lexend Deca', sans-serif;
}

.itemPreviewTitle {
  font-size: 16px;
  font-weight: 600;
  color: #292B3D;
  margin: 0;
}

.itemPreviewBrand {
  font-size: 14px;
  color: #555;
  margin: 0;
}

.itemPreviewId {
  font-size: 13px;
  color: #888;
  margin: 0;
}





.columnLabelRow {
  display: flex;
  justify-content: space-between; /* push toggle to far right */
  align-items: center;
}

.columnFormatToggle {
  display: flex;
  align-items: center;
  gap: 8px; /* space between checkbox and text label */
}
