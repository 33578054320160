/* src/popups/css/DeleteFeedPopup.module.css */

.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modalContent {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    max-width: 500px;
    width: 90%;
  }
  
  .errorMessage {
    color: red;
  }
  
  .confirmInput {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
  }
  
  .buttonsContainer {
    display: flex;
    justify-content: flex-end;
  }
  
  .cancelButton,
  .deleteButton {
    padding: 10px 20px;
    margin-left: 10px;
  }
  
  .deleteButton {
    background-color: #d9534f;
    color: #fff;
  }
  
  .loadingContainer {
    display: flex;
    align-items: center;
  }
  
  .spinner {
    margin-right: 10px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  