/* src/components/popups/css/PopupAssignClient.module.css */

.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1600;
    animation: fadeIn 0.3s forwards;
  }
  
  .modalContent {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    position: relative;
    animation: scaleIn 0.3s forwards;
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .closeButton {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    cursor: pointer;
    color: #555;
    transition: color 0.3s;
  }
  
  .closeButton:hover {
    color: #000;
  }
  
  .assignSection {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .assignSection label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
  }
  
  .assignButton {
    background-color: #4758EB;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .assignButton:hover {
    background-color: #364fc7;
  }
  
  .assignButton:disabled {
    background-color: #a0a0a0;
    cursor: not-allowed;
  }
  
  .cancelButton {
    background-color: #f0f0f0;
    color: #333;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .cancelButton:hover {
    background-color: #dcdcdc !important;
  }
  
  .error {
    color: #d66b7f;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .success {
    color: #4caf50;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .linkedUsersSection {
    margin-top: 30px;
  }
  
  .linkedUsersSection h3 {
    margin-bottom: 10px;
  }
  
  .linkedUsersList {
    list-style: none;
    padding: 0;
  }
  
  .linkedUserItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .username {
    font-weight: 500;
  }
  
  .unassignButton {
    background-color: #ff4d4f;
    color: #ffffff;
    border: none;
    padding: 5px;
    border-radius: 100px;
    font-size: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .unassignButton:hover {
    background-color: #d9363e;
  }
  
  .unassignButton:disabled {
    background-color: #a0a0a0;
    cursor: not-allowed;
  }
  
  /* Modal Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(0.9);
    }
    to {
      transform: scale(1);
    }
  }
  