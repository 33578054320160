/* src/components/css/Register.module.css */

.registerModal {
  /* Optional: Additional styles if needed */
}

.registerContainer {
  position: relative;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #4758EB;
  transition: color 0.3s;
}

.closeButton:hover {
  color: #EB4755;
}

.registerContainer h2 {
  margin-bottom: 20px;
  text-align: center;
  font-family: 'Lexend Deca', sans-serif;
  color: #4758EB;
}

.formGroup {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.formGroup label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #292B3D;
  font-family: 'Lexend Deca', sans-serif;
}

.formGroup input[type="text"],
.formGroup input[type="password"] {
  padding: 10px;
  border: 1px solid #E0E1EB;
  border-radius: 4px;
  font-family: 'Lexend Deca', sans-serif;
  transition: border-color 0.3s;
}

.formGroup input[type="text"]:focus,
.formGroup input[type="password"]:focus {
  border-color: #4758EB;
  outline: none;
}

.adminCheckboxLabel {
  margin-bottom: 5px;
  font-weight: bold;
  color: #292B3D;
  font-family: 'Lexend Deca', sans-serif;
}

.adminCheckboxContainer {
  display: flex;
  align-items: center;
}

.submitButton {
  width: 100%;
  padding: 10px;
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  transition: background-color 0.3s;
}

.submitButton:hover {
  background-color: #848EE0;
}

.message {
  margin-top: 10px;
  text-align: center;
  font-family: 'Lexend Deca', sans-serif;
}

.messageSuccess {
  color: green;
}

.messageError {
  color: red;
}
