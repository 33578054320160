/* src/components/css/Config.module.css */

.configPage {
  position: relative;
  font-family: 'Lexend Deca', sans-serif;
  color: #333;
}

.configContainer {
  padding: 0px;
  max-width: 900px;
  margin: 0 auto;
}

.header {
  text-align: center;
  margin-bottom: 30px;
}

.section {
  margin-top: 20px;
}

.noClientsMessage {
  padding: 1px;
  background-color: #fff3cd;
  border-left: 4px solid #ffeeba;
  border-radius: 4px;
  margin-bottom: 0px;
  color: #856404;
}

.clientSection {
  display: flex;
  flex-direction: column;
}

.clientLists {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.listContainer {
  flex: 1 1 45%;
  display: flex;
  flex-direction: column;
}

.listHeader {
  background-color: #f1f3f5;
  padding: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-weight: bold;
  color: #4758EB;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clientList {
  flex-grow: 1;
  max-height: 300px;
  overflow-y: auto;
  background-color: #fafafa;
  border: 1px solid #E0E1EB;
  border-top: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.clientItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px;
  border-bottom: 1px solid #E0E1EB;
  transition: background-color 0.2s;
}

.clientItem:hover {
  background-color: #f1f3f5;
}

.clientItem:last-child {
  border-bottom: none;
}

.clientName {
  flex-grow: 1;
  font-size: 14px;
}

.actionButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 6px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.linkButton {
  color: #28a745;
}

.linkButton:hover {
  background-color: rgba(40, 167, 69, 0.1) !important;
}

.unlinkButton {
  color: #dc3545;
}

.unlinkButton:hover {
  background-color: rgba(220, 53, 69, 0.1) !important;;
}

.searchInput {
  width: 100%;
  border: 1px solid #E0E1EB;
  border-radius: 4px;
  font-family: 'Lexend Deca', sans-serif;
  margin-bottom: 20px;
  margin-top:20px;
  transition: border-color 0.3s;
}

.searchInput:focus {
  border-color: #4758EB;
  outline: none;
}

.message {
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
}

.errorMessage {
  color: #dc3545;
}

.successMessage {
  color: #28a745;
}

@media (max-width: 768px) {
  .clientLists {
    flex-direction: column;
  }

  .listContainer {
    flex: 1 1 100%;
  }
}


.creditButton {
  color: #ffc107; /* Amber color for credit */
}

.creditButton:hover {
  background-color: rgba(255, 193, 7, 0.1) !important;
}