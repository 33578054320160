@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@400;600&display=swap');

.container {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  font-family: 'Lexend Deca', sans-serif;
  color: #292B3D;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.container h2 {
  color: #292B3D; /* Updated from #4758EB */
  margin-bottom: 20px;
}

/* Project Selection Styles */
.projectSelection {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.selectContainer {
  flex: 1;
}

.selectDropdown {
  width: 100%;
}

/* Button Group */
.buttonGroup {
  display: flex;
  align-items: center;
  margin-left: 10px; /* Space between dropdown and buttons */
}

/* Add Button Styles */
.addButton {
  background-color: #5CD685; /* Green */
  color: #ffffff;
  border: none;
  padding: 10px!important; /* Ensures padding as per provided CSS */
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px!important; /* Ensures width as per provided CSS */
  height: 40px!important; /* Ensures height as per provided CSS */
}

.addButton:hover {
  background-color: #94D1A8 !important; /* Lighter green on hover */
}

.addButton:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(92, 214, 133, 0.5);
}

/* Remove Button Styles */
.removeButton {
  background-color: #EB4755; /* Red */
  color: #ffffff !important; /* Ensures the icon color is white */
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px; /* Matches addButton size */
  height: 40px; /* Matches addButton size */
  font-size: 16px!important; /* Increased font size for better visibility */
  margin-left: 10px; /* Space between add and remove buttons */
  transition: background-color 0.3s;
}

.removeButton:hover {
  background-color: #E0858C !important; /* Lighter red on hover */
}

.removeButton:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(235, 71, 85, 0.5);
}

/* Toggle Container */
.updateAllButton {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px; /* Space between toggle and button */
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: background-color 0.3s;
}

.updateAllButton:hover {
  background-color: #848EE0;
}

.updateAllButton:disabled {
  background-color: #a0a0a0;
  cursor: not-allowed;
}

/* Adjust toggleContainer to align items */
.toggleContainer {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.toggleContainer label {
  margin-right: 10px;
  font-weight: 600;
}

.toggleContainer .react-toggle {
  margin-right: 10px;
}

/* Aggregation Section */
.aggregationSection {
  background-color: #f1f1f1;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 20px;
}

.aggregationSection h3 {
  color: #292B3D; /* Updated from #4758EB */
  margin-bottom: 15px;
}

.aggregationDropdowns {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.dropdownContainer {
  flex: 1;
  min-width: 200px;
  position: relative;
}

/* Advanced Toggle Styles */
.advancedToggle {
  margin-top: 10px;
}

.advancedToggleButton {
  background: none;
  border: none;
  color: #4758EB;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  padding: 0;
  transition: color 0.3s;
}

.advancedToggleButton:hover {
  color: #ffffff;
}

.advancedToggleButton:focus {
  outline: none;
  text-decoration: underline;
}

/* Advanced Options Panel */
.advancedOptionsPanel {
  margin-top: 10px;
  padding: 10px 0;
  border-top: 1px solid #e0e1eb;
  display: flex;
  flex-direction: column;
  gap: 15px;
  animation: fadeIn 0.3s ease-in-out;
}

/* Advanced Options Row for Separator and Level Side by Side */
.advancedOptionsRow {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.advancedOptionsRow .inputContainer {
  flex: 1;
  min-width: 200px;
}

/* Advanced Option Row */
.advancedOptionRow {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

/* Helper Text for Inputs */
.helperText {
  font-size: 12px;
  color: #6c757d;
  margin-top: 5px;
  font-family: 'Lexend Deca', sans-serif;
}

/* Improved Checkbox Container */
.checkboxContainer {
  display: flex;
  align-items: center;
  margin-bottom: 5px; /* Space between checkbox and helper text */
}

.checkboxContainer input {
  margin-right: 10px;
  width: 18px;
  height: 18px;
}

/* Info Icon Styling */
.infoIcon {
  margin-left: 5px;
  color: #6c757d;
  cursor: pointer;
  font-size: 14px;
}

.infoIcon:hover {
  color: #292B3D;
}

.exampleContainer label {
  font-weight: 600;
}

.exampleContainer p {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 4px;
  margin-top: 5px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
}

/* Highlighted Level in Example Group Value */
.highlightedLevel {
  background-color: #fff3cd;
  padding: 2px 4px;
  border-radius: 3px;
  font-weight: bold;
}

/* Filters Section Styles */
.filtersSection {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

/* Header for the filters section with toggle button */
.filtersHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filtersHeader h3 {
  color: #292B3D;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 18px;
  margin: 0;
}

.filtersToggleButton {
  background: none;
  border: none;
  color: #4758EB;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  transition: color 0.3s;
}

.filtersToggleButton:hover {
  color: #ffffff;
}

.filtersToggleButton:focus {
  outline: none;
  text-decoration: underline;
}

/* Panel that contains all the filter rows */
.filtersPanel {
  display: flex;
  flex-direction: column;
  gap: 15px;
  animation: fadeIn 0.3s ease-in-out;
}

/* Individual filter row */
.filterRow {
  display: flex;
  align-items: center; /* Changed from flex-start to center for vertical alignment */
  gap: 10px;
  flex-wrap: nowrap; /* Prevent wrapping to keep elements in a single row */
}

/* Connector dropdown (e.g., AND/OR) */
.connector {
  min-width: 80px;
  /* Removed margin-top to align vertically */
}

.connector .react-select__control {
  min-height: 40px;
}

.connector .react-select__menu {
  z-index: 1000;
}

/* Fields within a filter row */
.filterFields {
  display: flex;
  gap: 10px;
  flex: 1;
  min-width: 300px; /* Increased minimum width for better input visibility */
  align-items: center; /* Ensures vertical alignment */
}

.filterFields .react-select__control {
  min-height: 40px;
  flex: 1;
}

.filterFields .filterInput {
  flex: 2;
  padding: 10px;
  border: 1px solid #e0e1eb;
  border-radius: 4px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: border-color 0.3s;
}

.filterFields .filterInput:focus {
  border-color: #4758EB;
  outline: none;
  box-shadow: 0 0 0 2px rgba(71, 88, 235, 0.3);
}

/* Remove filter button */
.removeFilterButton {
  background-color: #EB4755;
  color: #ffffff;
  border: none;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-weight: bold;
  font-size: 12px;
  transition: background-color 0.3s;
  margin-left: 10px; /* Space between fields and remove button */
}

.removeFilterButton:hover {
  background-color: #E0858C;
}

/* Add filter button */
.addFilterButton {
  background-color: #5CD685;
  color: #ffffff;
  border: none;
  padding: 0;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 24px; /* Adjusted to align with FaPlus icon */
}

.addFilterButton:hover {
  background-color: #94D1A8;
}

/* Action Buttons */
.actionButtons {
  display: flex;
  gap: 10px;
  margin-top: 15px;
  flex-wrap: wrap;
}

.saveButton {
  background-color: #5CD685 !important;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  transition: background-color 0.3s;
}

.saveButton:hover {
  background-color: #94D1A8 !important;
}

.saveButton:disabled {
  background-color: #a0a0a0;
  cursor: not-allowed;
}

.previewButton {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  transition: background-color 0.3s;
}

.previewButton:hover {
  background-color: #848EE0 !important;
}

/* Project Details Styles */
.projectDetails {
  background-color: #f9f9f9;
  border-radius: 6px;
  padding: 15px;
  font-family: 'DM Sans', sans-serif;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.projectDetails h3 {
  color: #4758EB;
  margin-bottom: 10px;
}

.projectDetails p {
  margin: 5px 0;
}

/* Modal Styles */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(41, 43, 61, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 30px;
  width: 90%;
  max-width: 400px;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  font-family: 'Lexend Deca', sans-serif;
}

.closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  color: #292B3D;
  cursor: pointer;
  font-size: 18px;
  transition: color 0.3s ease;
}

.closeButton:hover {
  color: #4758EB;
}

.modalContent h3 {
  color: #292B3D; /* Updated from #4758EB */
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
}

.form label {
  margin-bottom: 5px;
  font-weight: 600;
}

.form input {
  padding: 10px;
  border: 1px solid #E0E1EB;
  border-radius: 4px;
  margin-bottom: 15px;
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.form input:focus {
  border-color: #4758EB;
  outline: none;
  box-shadow: 0 0 0 2px rgba(71, 88, 235, 0.3);
}

.submitButton {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #3641b7;
}

.error {
  color: #ff4d4f;
  margin-bottom: 10px;
  font-size: 14px;
}

/* Preview Section Styles */
.previewSection {
  margin-top: 40px;
  margin-bottom: 40px;
}

.previewSection h3 {
  color: #292B3D; /* Updated from #4758EB */
  margin-bottom: 10px;
}

.previewTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.previewTable th,
.previewTable td {
  border: 1px solid #ddd;
  padding: 8px;
}

.previewTable th {
  background-color: #4758EB;
  color: #ffffff;
  text-align: left;
}

.previewTable tr:nth-child(even) {
  background-color: #f9f9f9;
}

.previewTable tr:hover {
  background-color: #f5f5f5;
}

.examplePreviewSection {
  margin-top: 20px;
}

.examplePreviewSection h3 {
  color: #292B3D; /* Updated from #4758EB */
  margin-bottom: 10px;
}

.examplePreviewSection table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.examplePreviewSection th,
.examplePreviewSection td {
  border: 1px solid #ddd;
  padding: 8px;
}

.examplePreviewSection th {
  background-color: #4758EB;
  color: #ffffff;
  text-align: left;
}

.examplePreviewSection tr:nth-child(even) {
  background-color: #f9f9f9;
}

.examplePreviewSection tr:hover {
  background-color: #f5f5f5;
}

/* Loading Spinner for Preview */
.sectionSpinner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

/* Global Spinner Overlay */
.globalSpinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Custom Option Label Styles */
.optionLabel {
  display: flex;
  align-items: center;
}

.activeDot,
.inactiveDot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

.activeDot {
  background-color: #5CD685; /* Green */
}

.inactiveDot {
  background-color: #EB4755; /* Red */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .aggregationDropdowns {
    flex-direction: column;
  }

  .filtersSection .filterRow {
    flex-direction: column;
    align-items: flex-start;
  }

  .connector {
    width: 100%;
    margin-bottom: 5px;
  }

  .filterFields {
    flex-direction: column;
    width: 100%;
  }

  .filterFields .react-select__control {
    width: 100%;
  }

  .filterFields .filterInput {
    width: 100%;
  }

  .actionButtons {
    flex-direction: column;
    gap: 10px;
  }

  .advancedOptionsRow {
    flex-direction: column;
    gap: 10px;
  }

  .toggleContainer {
    flex-direction: column;
    align-items: flex-start;
  }
  .lastUpdatedAt,
  .lastErrorMessage {
    margin-left: 0;
    margin-top: 10px;
  }

  .toggleContainerInline {
    flex-direction: column; /* Stack vertically on smaller screens */
    align-items: flex-start;
  }
}

/* Inline Input for Attribute Name */
.inlineInputContainer {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between label and input */
  margin-bottom: 15px; /* Space below the input field */
}

/* Specific Styling for Attribute Name Input */
.attributeInput {
  width: 100%; /* Changed from 300px to 100% for responsiveness */
  max-width: 400px; /* Optional: Set a max-width for larger screens */
  padding: 10px;
  border: 1px solid #E0E1EB;
  border-radius: 4px;
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.attributeInput:focus {
  border-color: #4758EB;
  outline: none;
  box-shadow: 0 0 0 2px rgba(71, 88, 235, 0.3);
}

/* Adjusted filterInput for better visibility */
.filterInput {
  flex: 2; /* Increased flex to allocate more space */
  min-width: 250px; /* Ensures a minimum width */
  width: 100%; /* Allows the input to expand as needed */
  max-width: 500px; /* Optional: Prevents overly wide inputs on large screens */
}

/* Style for Last Updated At */
.lastUpdatedAt {
  margin-left: 15px;
  font-size: 14px;
  color: #6c757d;
  font-family: 'Lexend Deca', sans-serif;
}

/* Style for Last Error Message */
.lastErrorMessage {
  margin-left: 15px;
  font-size: 14px;
  color: #ff4d4f;
  font-family: 'Lexend Deca', sans-serif;
}

/* InfoBox Styles */
.infoBox {
  background-color: #e7f3fe;
  color: #292B3D;
  padding: 15px;
  border-left: 6px solid #5CC0D6;
  border-radius: 4px;
  margin-bottom: 20px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
}

/* Add these styles for the inline toggle beside the attribute name */
.toggleContainerInline {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between label and toggle */
}

.toggleContainerInline label {
  font-weight: 600;
  margin-right: 5px; /* Space between label and toggle */
}

/* Success Message */
.successMessage {
  color: #5CD685; /* Green color */
  font-size: 14px;
  margin-top: 10px;
  font-family: 'Lexend Deca', sans-serif;
}

/* Unsaved Changes Message */
.unsavedChangesMessage {
  color: #EB4755; /* Red color */
  font-size: 14px;
  margin-top: 10px;
  font-family: 'Lexend Deca', sans-serif;
}
