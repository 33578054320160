/* src/popups/css/TransformPopup.module.css */

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup {
    background-color: #fff;
    padding: 20px;
    width: 400px;
    max-width: 90%;
    border-radius: 8px;
    position: relative;
    text-align: center;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .previewImage {
    width: 100%;
    max-height: 200px;
    object-fit: contain;
    margin-bottom: 20px;
  }
  
  .optionsContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .transformButton {
    background-color: #4758EB;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 16px;
  }
  
  .transformButton:hover {
    background-color: #3949ab;
  }
  
  .transformButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  

  /* ... (existing styles) */

.message {
    margin-top: 20px;
    font-size: 14px;
    color: #292B3D;
    font-family: 'Lexend Deca', sans-serif;
  }
  
  .success {
    color: #5CD685;
  }
  
  .error {
    color: #EB4755;
  }
  
  .noOptions {
    font-size: 14px;
    color: #999;
    font-family: 'Lexend Deca', sans-serif;
    margin-top: 10px;
  }
  

  /* Add these styles to your TransformerPage.module.css */

.successMessage {
  padding: 15px;
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  border-radius: 4px;
  margin-top: 20px;
}

.errorMessage {
  padding: 15px;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  margin-top: 20px;
}
