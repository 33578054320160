/* src/popups/css/PopupBulkActions.module.css */

.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup {
    background-color: #ffffff;
    width: 95%;
    max-width: 2440px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 90%;
    max-height: 1000px;
  }
  
  .headerAndTabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px; /* Reduced padding */
    padding-top: 20px; /* Reduced padding */
    padding-right: 16px; /* Reduced padding */
    background-color: #FFFFFF;
    color: #292B3D;
  }
  
  .headerLeft {
    display: flex;
    align-items: center; /* Center title and tabs vertically */
    font-family: 'DM Serif Text', serif;
    flex-grow: 1; /* Allow the header left to grow */
  }
  
.headerTitle h2 {
    font-size: 20px; /* Reduced font size */
    color: #292B3D;
    margin-right: 0;
}
  
.tabContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
}
  
.tabGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 16px; /* Reduced margin */
    padding-right: 16px; /* Reduced padding */
    border-right: 1px solid #E0E1EB;
}

.tabGroup:last-child {
    margin-right: 0;
    padding-right: 0;
    border-right: none;
}
  
  
  .groupLabel {
    font-family: 'Lexend Deca', sans-serif;
    font-size: 11px;
    color: #4758EB;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: bold;
    margin-bottom: 4px;
  }
  
  .tabRow {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 4px; /* Reduced margin */
}
  
  .tabButton {
    padding: 6px 10px;
    background-color: transparent;
    border: 2px dashed transparent; /* Reserve space for the border */
    color: #292B3D;
    cursor: pointer;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 13px;
    display: flex;
    align-items: center;
    transition: background-color 0.2s, border-color 0.2s; /* Smooth transition */
    border-radius: 4px;
    margin-right: 8px; /* Reduced margin */
    margin-bottom: 4px; /* Reduced margin */
    box-sizing: border-box; /* Include padding and border in size */
  }
  
  .tabButton:hover {
    background-color: #E0E1EB !important;
  }
  
  .activeTab {
    box-sizing: border-box; /* Ensure border is included in the element's width and height */
    border: 2px dashed #292B3D !important;
    color: #292B3D;
    border-radius: 4px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  }
  
  
  .tabIcon {
    margin-right: 6px;
  }
  
  .closeButton {
    background-color: transparent;
    border: none;
    color: #292B3D;
    cursor: pointer;
    margin-left: auto; /* Pushes the button to the right */
  }
  
  .contentArea {
    padding: 20px;
    overflow-y: auto;
    flex-grow: 1;
    background-color: #f9f9f9;
    border-radius: 8px;
    margin-top: 20px;
  }
  

  