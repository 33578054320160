body {
  background-color: #F3F3F7;
  font-family: 'DM Sans', sans-serif;
  color: #333;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

#app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100vw;
}

#app-container.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.config-button, .logout-button, button {
  background-color: #6c7bbf;
  color: white;
  border: none;
  padding: 8px 12px;
  margin-top: 5px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.config-button:hover, .logout-button:hover, button:hover:not(:disabled) {
  background-color: #565fb3;
}

button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.main-content {
  width: 100%;
  max-width: 98%;
  padding: 20px;
}

.column-section {
  background: #FFF;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.list-container {
  display: flex;
  align-items: stretch;
  gap: 20px;
}

.scrollable-list-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 280px;
}

.scrollable-list-container h3 {
  font-size: 18px;
  font-family: 'Lexend Deca', sans-serif;
  color: #292B3D;
  margin-bottom: 10px;
}

.scrollable-list {
  flex: 1;
  overflow-y: auto;
  max-height: 250px;
  background-color: #ffffff;
  border: 1px solid #E0E1EB;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.scrollable-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.scrollable-list li {
  display: flex;
  align-items: center;
  padding: 6px;
  margin-bottom: 2px;
  background-color: #F3F3F7;
  border: 1px solid #E0E1EB;
  border-radius: 6px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  color: #292B3D;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.scrollable-list li:hover {
  background-color: #E0E1EB;
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);
}

.scrollable-list li.selected {
  background-color: #4758EB;
  color: #ffffff;
  border-color: #4758EB;
}

.scrollable-list li.selected:hover {
  background-color: #3949ab;
}

.scrollable-list li img {
  margin-left: auto;
  width: 20px;
  height: 20px;
}

.scrollable-list li .column-name {
  flex-grow: 1;
}

.scrollable-list li .icon {
  margin-right: 10px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.table-controls {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 600px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  font-family: 'Lexend Deca', sans-serif;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.button-group-column-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.button-group-column-selection button {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  width: 40px;
  height: 40px;
}

.button-group-column-selection button:hover {
  background-color: #6c7bbf;
}

.button-group-column-selection button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

th, td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}

th {
  background: #848EE0;
  color: white;
  position: relative;
  top: 0;
  z-index: 1;
  font-weight: 500;
}

td input[type="text"], td textarea {
  width: 100%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

td textarea {
  resize: vertical;
  min-height: 50px;
}

.editable-textarea {
  display: block;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  min-height: 50px;
  max-height: 200px;
  resize: vertical;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  background-color: #f9f9f9;
  overflow-x: hidden;
  transition: border-color 0.3s, background-color 0.3s;
}

.editable-textarea:hover{
  border-color: #848EE0;
}

.editable-textarea:focus {
  outline: none;
  border-color: #848EE0;
  background-color: #f9f9fe;
}

button:disabled:hover {
  background: #ccc;
  cursor: not-allowed;
}

.field-selection {
  margin-top: 20px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.field-selection h3 {
  margin-bottom: 10px;
}

.field-selection {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.field-list {
  list-style: none;
  padding: 0;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  border: 0;
  border-radius: 5px;
  margin-bottom: 20px;
}

.field-list li:last-child {
  border-bottom: none;
}

.field-list li.selected {
  background-color: #848EE0;
  color: white;
}

.field-list li.selected:hover {
  background-color: #6b76d5;
}

.field-list li:hover {
  background-color: #e0e0e0;
}

.field-selection-collapsible {
  margin-top: 20px;
  margin-bottom: 50px;
}

.field-selection-toggle {
  display: none;
}

.field-selection-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
}

.collapsible-icon::before {
  content: '+';
  margin-left: 10px;
  font-size: 22px;
}

.field-selection-content {
  display: none;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin-top: 10px;
}

.field-selection-toggle:checked + .field-selection-label .collapsible-icon::before {
  content: '-';
}

.field-selection-toggle:checked + .field-selection-label + .field-selection-content {
  display: block;
}

.logout-button {
  background-color: #ff4d4d;
  border: none;
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 5px;
}

.logout-button:hover {
  background-color: #d43d3d;
}

.global-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.global-loading-spinner {
  background: white;
  padding: 20px;
  border-radius: 50%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

body.resizing {
  user-select: none;
  cursor: col-resize;
}

td input[type="checkbox"] {
  width: auto;
  margin: 0;
  transform: scale(1.2);
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.table-container {
  margin-bottom: 80px;
  overflow-x: auto;
  display: block;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: auto;
  position: relative;
}

.full-width-table th, .full-width-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  position: relative;
}

.full-width-table {
  width: max-content;
}

.full-width-table th {
  background: #4758EB;
  color: white;
}

th div, td div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

th div div,
td div div {
  width: 10px;
  height: 100%;
  cursor: col-resize;
  position: absolute;
  right: 0;
  top: 0;
  background-color: transparent;
  z-index: 1;
}

.ai-generated-icon {
  margin-left: 5px;
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.saving {
  position: relative;
  background-color: #f3f3f3;
}

.saving:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-image: url('https://storage.googleapis.com/quantum-feed-engine/qfe_toolbox/media/loading.gif');
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 10;
}

.replace-input-group {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.replace-input-group label {
  font-size: 16px;
  font-weight: bold;
}

.replace-input-group input,
.replace-input-group select {
  flex: 1;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.3s;
}

.replace-input-group input:focus,
.replace-input-group select:focus {
  border-color: #848ee0;
  outline: none;
}

.replace-input-group select {
  width: auto;
  min-width: 150px;
}

.replace-case-sensitive {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 10px;
}

.replace-case-sensitive label {
  font-weight: bold;
  font-size: 16px;
}

.replace-case-sensitive input[type="radio"] {
  margin-right: 5px;
  transform: scale(1.2);
  accent-color: #848ee0;
}

button { 
  padding: 6px 12px; 
  margin: 5px; 
  background-color: #848EE0; 
  color: white; 
  border: none; 
  cursor: pointer; 
  font-size: 0.8rem; 
  border-radius: 5px; 
  white-space: nowrap;
}

input, select { 
  padding: 6px; 
  margin: 5px; 
  border: 1px solid #ccc; 
  width: 100%; 
  box-sizing: border-box; 
  font-size: 0.8rem;
  border-radius: 5px;
}

th, td { 
  border: 1px solid #E0E1EB;
  padding: 10px;
  text-align: left;
  font-size: 0.8rem;
}

li {
  padding: 8px;
  margin-bottom: 4px;
  background: #F3F3F7;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  max-height: 10px;
  font-size: 0.8rem;
}

li.selected {
  background: #848EE0;
  color: #fff;
}

li img {
  margin-left: 5px;
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.field-list li {
  padding: 10px;
  margin: 0;
  background-color: #f3f3f3;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  display: flex;
  align-items: center;
}

img, video {
  max-width: 100px;  
  max-height: 100px; 
  width: auto;       
  height: auto;      
  object-fit: contain;
  margin-right: 5px;
}

table {
  width: auto; 
  margin-top: 20px;
  border-collapse: collapse;
  background: white; 
  border: 1px solid #E0E1EB; 
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  border-radius: 5px;
  overflow: hidden;
}

.PromptbuilderButton {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-left: 10px;
  margin-right: -5px;
}

.PromptbuilderButton:hover {
  background-color: #848EE0 !important;
}

.top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  margin-top: 20px;
  margin-right: 18px;
  margin-left: 18px;
  background-color: #ffffff;
  color: #4758EB;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  gap: 10px;
  flex-wrap: nowrap;
  box-sizing: border-box; 
  align-self: stretch; 
}

.left-section, .right-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.left-section {
  flex: 1;
}

.right-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.client-select {
  min-width: 200px;
}

.header-text-container {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  min-width: 250px;
}

.feed-viewer-version {
  font-size: 14px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 600;
  color: #4758EB;
}

.header-subtitle {
  font-size: 22px;
  font-family: 'DM Serif Text', serif;
  color: #292B3D;
}

.header-image {
  height: 100px;
  width: auto;
  padding-top: 20px;
  object-fit: contain;
  margin-left: auto;
}

.back-button {
  background-color: #d0d0d0;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.back-button:hover {
  background-color: #7c7c7c !important;
}

.logout-button {
  background-color: #E0858C;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.logout-button:hover {
  background-color: #EB4755 !important;
}

.refresh-button {
  background-color: transparent;
  color: #d0d0d0;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.refresh-button:hover {
  color: #4758EB;
  background-color: #ffffff !important;
}

.config-button {
  background-color: transparent;
  color: #d0d0d0;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.config-button:hover {
  color: #4758EB;
  background-color: #ffffff !important;
}

.credit-info {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 20px;
  color: #292B3D;
  margin-left: 20px;
}

.credit-info span {
  font-size: 12px;
  font-family: 'Lexend Deca', sans-serif;
  color: #d0d0d0;
}

.credit-info i {
  color: #d0d0d0;
}

.no-client-selected {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  text-align: center;
  color: #555;
}

.no-client-selected h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.no-client-selected p {
  font-size: 16px;
  max-width: 400px;
}

.selected-columns-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.selected-columns-header h3 {
  margin: 0;
  margin-right: 1rem;
}

.column-configuration {
  display: flex;
  align-items: center;
}

.configuration-select {
  min-width: 150px;
  max-width: 200px;
  margin-right: 0.5rem;
}

.addColConfigButton {
  background-color: #5CD685;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  width: 20px;
  height: 20px;
}

.addColConfigButton:hover {
  background-color: #94D1A8 !important;
}

.addColConfigButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.RmColConfigButton {
  background-color: #EB4755;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  width: 20px;
  height: 20px;
}

.RmColConfigButton:hover {
  background-color: #E0858C !important;
}

.RmColConfigButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.filter-section:not(.hidden) .filter-box {
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  font-family: 'Lexend Deca', sans-serif;
}

.filter-group {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 0px;
  margin-top: 10px;
}

.filter-group select,
.filter-group input[type="text"] {
  padding: 8px;
  border: 1px solid #e0e1eb;
  border-radius: 4px;
  font-family: 'Lexend Deca', sans-serif;
  transition: border-color 0.3s;
  font-size: 12px;
  background-color: #f4f4f4;
}

.filter-group select:focus,
.filter-group input[type="text"]:focus {
  border-color: #4758EB;
}

.filter-remove-button {
  background-color: #EB4755;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  width: 20px;
  height: 20px;
  font-size: 10px!important;
  transition: background-color 0.3s;
}

.filter-remove-button:hover {
  background-color: #E0858C !important;
}

.filter-add-button {
  background-color: #5CD685 !important;
  color: #ffffff !important;
  border: none !important;
  border-radius: 50% !important;
  cursor: pointer !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 40px !important;
  height: 40px !important;
  transition: background-color 0.3s !important;
}

.filter-add-button:hover {
  background-color: #94D1A8 !important;
}

.filter-action-button {
  background-color: #4758EB !important;
  color: #ffffff !important;
  border: none !important;
  border-radius: 50% !important;
  cursor: pointer !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 40px !important;
  height: 40px !important;
  transition: background-color 0.3s !important;
  padding: 0 !important;
}

.filter-action-button:hover {
  background-color: #848EE0 !important;
}

.order-by-select {
  min-width: 80px;
  max-width: 200px;
  padding-left: 8px;
  border: 1px solid #e0e1eb !important;
  border-radius: 4px !important;
  font-family: 'Lexend Deca', sans-serif !important;
  background-color: #f4f4f4 !important;
  transition: border-color 0.3s !important;
  font-size: 12px !important;
}

.order-by-select:focus {
  border-color: #4758EB !important;
}

.filter-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-top: 10px;
  font-family: 'Lexend Deca', sans-serif;
}

.filter-actions label {
  margin-right: 10px;
}

.filter-actions label:last-of-type {
  margin-right: auto;
}

.filter-actions button {
  margin-left: 0px;
}

.nav-button.icon-button {
  background-color: #4758EB;
  color: white;
  border: none;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  width: 26px;
  height: 26px;
}

.nav-button.icon-button:hover {
  background-color: #565fb3;
}

.nav-button.icon-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.pagination-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.pagination-inputs {
  display: flex;
  align-items: center;
  font-size: 12px;
  gap: 10px;
}

.pagination-label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.page-input {
  min-width: 10px;
  max-width: 100px;
  text-align: center;
}

.input-select,
.input-text {
  font-size: 12px;
  font-family: 'Lexend Deca', sans-serif;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px 6px;
  transition: border-color 0.3s;
  outline: none;
}

.input-text {
  width: 40px;
  text-align: center;
}

.input-select:focus,
.input-text:focus {
  border-color: #565fb3;
}

.pagination-total {
  font-size: 12px;
  color: #666;
}

.pagination-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.action-button {
  background-color: #4758EB;
  color: white;
  border: none;
  padding: 8px 16px;
  margin: 5px;
  border-radius: 40px;
  font-family: 'Lexend Deca', sans-serif;
  cursor: pointer;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
}

.action-button:hover:not(:disabled) {
  background-color: #565fb3;
}

.action-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.publish-button {
  background-color: #4758EB;
  color: white;
  border: none;
  font-family: 'Lexend Deca', sans-serif;
  padding: 8px 16px;
  margin: 5px;
  border-radius: 40px;
  cursor: pointer;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
}

.publish-button:hover:not(:disabled) {
  background-color: #565fb3;
}

.publish-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.download-button {
  background-color: #d0d0d0;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  size: 6px;
  padding: 11px;
  font-size: 10px;
}

.download-button:hover:not(:disabled) {
  background-color: #7c7c7c;
}

.download-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.full-width-table-container {
  margin-top: 20px;
}

.toolbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  gap: 16px;
}

.button-group {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Updated progress bar container to ensure the progress bar grows */
.progress-info.running-progress .progress-details {
  flex: 1;
}
.progress-status-container {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
  width: 300px; /* Adjust as needed */
}

.progress-bar-container {
  flex: 1;
  background-color: #e0e0e0;
  border-radius: 6px;
  height: 10px;
  overflow: hidden;
  position: relative;
}

.progress-bar {
  background-color: #ff9800;
  height: 100%;
  transition: width 0.4s ease;
}

.progress-info-container {
  flex-shrink: 0;
}

.progress-counter {
  font-size: 0.85rem;
  color: #333;
  white-space: nowrap;
}


.progress-info {
  display: flex;
  align-items: center;
  min-width: 350px;
  max-width: 100%;
  gap: 8px;
  color: #333;
  margin-bottom: 4px;
}

.status-icon {
  font-size: 18px;
  color: #4758EB;
}

.cancel-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: #EB4755;
  color: white;
  border: none;
  padding: 4px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-size: 10px;
  transition: background-color 0.3s, transform 0.2s;
}

.cancel-button:hover {
  background-color: #E0858C !important;
  transform: scale(1.1);
}

.cancel-button:active {
  transform: scale(0.95);
}

.cancel-button:disabled {
  background-color: #e0aeb1;
  cursor: not-allowed;
  transform: none;
}

.progress-info.running-progress {
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: #fff8e1;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.progress-info.running-progress .progress-details strong {
  display: block;
  font-size: 1rem;
  color: #ff9800;
}

.progress-info.running-progress .progress-details span {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 6px;
}

.progress-bar-container .progress-bar {
  background-color: #ff9800;
}

.progress-info.completed-progress {
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: #E0EBE4;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.progress-info.completed-progress .progress-details strong {
  display: block;
  font-size: 1rem;
  color: #5CD685;
}

.progress-info.completed-progress .progress-details span {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 6px;
}

.progress-info.cancelled-progress {
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: #ffebee;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.progress-info.cancelled-progress .progress-details strong {
  display: block;
  font-size: 1rem;
  color: #f44336;
}

.progress-info.cancelled-progress .progress-details span {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 6px;
}

.progress-info.error-progress {
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: #ffebee;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.progress-info.error-progress .progress-details strong {
  display: block;
  font-size: 1rem;
  color: #f44336;
}

.progress-info.error-progress .progress-details span {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 6px;
}

.progress-info.timeout-progress {
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: #fff3e0;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.progress-info.timeout-progress .progress-details strong {
  display: block;
  font-size: 1rem;
  color: #ff9800;
}

.progress-info.timeout-progress .progress-details span {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 6px;
}

.progress-info.unknown-progress {
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: #e3f2fd;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.progress-info.unknown-progress .progress-details strong {
  display: block;
  font-size: 1rem;
  color: #2196f3;
}

.progress-info.unknown-progress .progress-details span {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 6px;
}

.progress-info.no-progress {
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: #e0e0e0;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.progress-info.no-progress .progress-details strong {
  display: block;
  font-size: 1rem;
  color: #757575;
}

.progress-info.no-progress .progress-details span {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 6px;
}

.running-progress .status-icon {
  color: #ffa500;
}

.completed-progress .status-icon {
  color: #5CD685;
}

.cancelled-progress .status-icon,
.error-progress .status-icon,
.timeout-progress .status-icon {
  color: #f44336;
}

.unknown-progress .status-icon {
  color: #2196f3;
}

.no-progress .status-icon {
  color: #757575;
}
