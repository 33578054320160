/* Overlay Styles */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Lexend Deca', sans-serif;
}

/* Popup Inner Container */
.popup-inner {
    background: #ffffff;
    max-width: 1200px; 
    width: 90%;
    padding: 20px;
    position: relative;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 80vh;
    overflow-y: auto;
}

/* Override any conflicting styles by increasing specificity */
.popup-editable-textarea.description-textarea {
    min-height: 200px !important; /* Use !important as a last resort */
    height: auto !important;
}

/* Close Button */
.new-popup-close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    border: none;
    background: #E0858C;
    color: #ffffff;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    cursor: pointer;
    font-size: 20px;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.new-popup-close-button:hover {
    background-color: #EB4755 !important;
}

/* Header Section */
.popup-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    color: #292B3D;
    font-family: 'Lexend Deca', sans-serif;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.popup-preview {
    display: flex;
    align-items: flex-start;
    gap: 20px;
}

.popup-preview-image img {
    max-width: 120px;
    border-radius: 8px;
    object-fit: contain;
}

.popup-preview-details p {
    margin: 5px 0;
    font-size: 14px;
    color: #292B3D;
}

.popup-controls {
    display: flex;
    align-items: center;
    gap: 10px;
}

.popup-save-button {
    background: #4758EB;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 14px;
    transition: background-color 0.3s;
}

.popup-save-button:hover {
    background-color: #848EE0 !important;
}

/* Generate QFE Fields Section */
.field-selection-container {
    margin-top: 20px;
    background-color: #f9f9fc;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #ddd;
}

.field-selection-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.field-selection-header h3 {
    margin: 0;
    font-size: 16px;
    color: #292B3D;
    font-weight: 600;
}

.field-selection-actions {
    display: flex;
    align-items: center;
    gap: 10px;
}

.field-selection-actions button {
    background-color: #4758EB;
    color: #ffffff;
    border: none;
    padding: 8px 12px;
    border-radius: 20px;
    cursor: pointer;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 12px;
    transition: background-color 0.3s;
}

.field-selection-actions button:hover {
    background-color: #848EE0 !important;
}

.field-selection-actions select {
    font-size: 12px;
    padding: 5px 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-family: 'Lexend Deca', sans-serif;
}

.popup-generate-button {
    background: #4758EB;
    color: #ffffff;
    border: none;
    padding: 8px 16px;
    border-radius: 20px;
    cursor: pointer;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 14px;
    transition: background-color 0.3s;
}

.popup-generate-button:hover {
    background-color: #848EE0 !important;
}

.field-selection-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.field-item {
    background: #ffffff;
    border: 1px solid #ccc;
    padding: 6px 10px;
    border-radius: 8px;
    font-size: 12px;
    font-family: 'Lexend Deca', sans-serif;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.field-item.selected {
    background: #4758EB;
    color: #ffffff;
}

.field-item:hover {
    background-color: #e0e1eb;
}

.field-item input[type="checkbox"] {
    margin: 0;
}


/* Row Details Section */
.new-row-details {
    margin-top: 20px;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 14px;
    color: #292B3D !important;
}

.new-row-detail {
    display: flex;
    width: 100%; /* Ensure full width */
    margin-bottom: 10px;
    background-color: #ffffff;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

/* Equal Widths for Left and Right Sections */
.new-row-detail-left {
    width: 45%;
    margin: 0;
    color: #292B3D;
    padding-right: 20px;
    word-break: break-word;
}

.new-row-detail-left strong {
    color: #292B3D;
}

.new-row-detail-right {
    width: 55%;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.new-row-detail-right strong {
    color: #292B3D;
}

/* Editable Textarea */
.popup-editable-textarea {
    width: 100%;
    min-height: 50px;
    resize: vertical;
    font-family: 'Lexend Deca', sans-serif;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 8px;
    transition: border-color 0.3s;
}

.popup-editable-textarea:focus {
    border-color: #4758EB;
}

/* Toggle Button */
.toggle-button {
    margin-left: 10px;
    padding: 0.5em 1em;
    cursor: pointer;
    font-family: 'Lexend Deca', sans-serif;
    border-radius: 20px;
    border: none;
    width: 110px;
    text-align: center;
    transition: background-color 0.3s, opacity 0.3s;
}

.toggle-button.active {
    background: #94D1A8;
    color: #ffffff;
}

.toggle-button.deactivate {
    background: #f8eec0;
    color: #292B3D;
}

.toggle-button.active:hover {
    background: #94D1A8;
    color: #ffffff;
    opacity: 0.7 !important;
}

.toggle-button.deactivate:hover {
    background: #f1e5ad;
    color: #292B3D;
    opacity: 0.7 !important;
}

.toggle-button.loading {
    cursor: not-allowed;
    opacity: 0.6;
}

/* Loading States */
.saving {
    opacity: 0.6;
}
/* Unsaved and Success Messages */
.popup-unsaved {
    color: red;
    font-weight: bold;
    margin-top: 10px;
    font-family: 'Lexend Deca', sans-serif;
    text-align: center;
}

.popup-success {
    color: green;
    font-weight: bold;
    margin-top: 10px;
    font-family: 'Lexend Deca', sans-serif;
    text-align: center;
}

/* Warning Overlay */
.popup-warning-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Lexend Deca', sans-serif;
}

.popup-warning-inner {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
}

.popup-warning-inner p {
    margin-bottom: 20px;
    font-size: 14px;
    color: #292B3D;
}

.popup-warning-inner button {
    background: #4758EB;
    color: #ffffff;
    border: none;
    padding: 8px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-family: 'Lexend Deca', sans-serif;
    margin: 0 10px;
    font-size: 14px;
    transition: background-color 0.3s;
}

.popup-warning-inner button:hover {
    background-color: #848EE0 !important;
}

/* General Button Styles */
.popup-save-button,
.popup-generate-button,
.popup-warning-inner button {
    width: 100px;
}

.toggle-button,
.popup-save-button,
.popup-generate-button,
.popup-warning-inner button {
    height: 35px;
}


/* Sticky Save Button Container */
.sticky-save-container {
    position: sticky;
    bottom: 0;
    background-color: #ffffff;
    padding: 10px 0;
    border-top: 1px solid #ddd;
    display: flex;
    justify-content: flex-end;
    z-index: 10;
}

/* Separate Class for the Save Button */
.sticky-save-button {
    background: #5CD685;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    width: 130px;
    border-radius: 20px;
    cursor: pointer;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 14px;
    transition: background-color 0.3s, box-shadow 0.3s;
    /* Remove fixed width */
    /* width: 140px; */ /* This line is removed */
    display: flex;
    align-items: center;
    justify-content: center;
}

.sticky-save-button:hover {
    background-color: #94D1A8 !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.sticky-save-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}


/* Optional: Smooth scrolling when content is behind the sticky button */
.new-popup-inner {
    overflow-y: auto;
    padding-bottom: 0px; /* Ensure content isn't hidden behind the sticky button */
}
