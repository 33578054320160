@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap');

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  width: 90%;
  max-width: 1100px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  font-family: 'Lexend Deca', sans-serif;
  position: relative;
  overflow-y: auto;
  max-height: 90vh;
}

.modalTitle {
  margin-bottom: 20px;
  font-size: 24px;
  color: #4758eb;
  text-align: center;
}

.stepContainer {
  margin-bottom: 30px;
  position: relative; /* For overlay positioning */
}

.stepHeader {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.stepNumber {
  width: 30px;
  height: 30px;
  background-color: #4758EB;
  color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 10px;
}

.stepTitle {
  font-size: 18px;
  color: #292B3D;
}

.formGroup {
  margin-bottom: 20px;
}

.label {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-weight: 600;
  color: #292b3d;
}

.inputField {
  width: 100%;
  padding: 10px;
  border: 1px solid #e0e1eb;
  border-radius: 4px;
  font-family: 'Lexend Deca', sans-serif;
  transition: border-color 0.3s;
}

.inputField:focus {
  border-color: #4758eb;
  outline: none;
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.cancelButton,
.nextButton {
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  font-family: 'Lexend Deca', sans-serif;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;
}

.cancelButton {
  background-color: #e0e1eb;
  color: #292B3D;
}

.cancelButton:hover {
  background-color: #c5c7d6;
}

.nextButton {
  background-color: #5CD685;
  color: #ffffff;
}

.nextButton:disabled {
  background-color: #E0EBE4 !important;
  cursor: not-allowed;
}

.nextButton:hover:not(:disabled) {
  background-color: #94D1A8 !important;
}

.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1001;
}

.loadingOverlay p {
  margin-top: 10px;
  font-size: 16px;
  color: #4758EB;
}

.errorMessage {
  display: flex;
  align-items: center;
  background-color: #f8d7da; /* Light red background */
  color: #721c24; /* Dark red text */
  padding: 10px;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  margin-top: 10px;
}

.errorIcon {
  margin-right: 10px;
  font-size: 20px;
}
.successMessage {
  display: flex;
  align-items: center;
  background-color: #d4edda; /* Light green background */
  color: #155724; /* Dark green text */
  padding: 10px;
  border: 1px solid #c3e6cb;
  border-radius: 4px;
  margin-top: 10px;
}

.successIcon {
  margin-right: 10px;
  font-size: 20px;
}

.iconGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 10px;
}

.iconOption {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  padding: 10px;
  border: 1px solid #e0e1eb;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.iconOption span {
  margin-top: 5px;
  font-size: 12px;
  color: #292b3d;
  text-align: center;
}

.iconOption:hover {
  background-color: #f0f0f0;
}

.selectedOption {
  border-color: #4758eb;
  background-color: #e8eaff;
}

.pimLogo,
.dataTypeIcon {
  width: 40px;
  height: 40px;
}

.tooltip {
  position: relative;
  display: inline-block;
  margin-left: 5px;
  color: #d0d0d0;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: #292B3D;
  color: #fff;
  text-align: left;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 25px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.inputWithFeedback {
  position: relative;
  display: flex;
  align-items: center;
}

.feedbackMessage {
  min-height: 24px; /* Adjust based on your message height */
  margin-top: 5px;
}

.spinner {
  position: absolute;
  right: 10px;
  animation: spin 1s linear infinite;
  color: #4758eb;
}

.mappingButton {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: all 0.2s ease;
  margin-top: 10px;
}

.mappingButton:hover {
  background-color: #6b79f7;
}

.mappingButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Data Options */
.dataOptions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0px;
  position: relative;
}

.dataOptions::before {
  left: 0;
}

.dataOptions::after {
  right: 0;
}

.tagInput {
  width: 150px; /* Limited width */
  padding: 10px;
  border: 1px solid #e0e1eb;
  border-radius: 4px;
  font-family: 'Lexend Deca', sans-serif;
  transition: border-color 0.3s;
}

.tagInput:focus {
  border-color: #4758eb;
  outline: none;
}

/* File Info */
.fileInfo {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-family: 'Lexend Deca', sans-serif;
  color: #4758eb;
}

.fileInfo button {
  margin-left: auto;
  background: none;
  border: none;
  color: #eb4755;
  cursor: pointer;
  font-size: 14px;
}

.fileInfo button:hover {
  text-decoration: underline;
}

/* Data Options and Or Divider */
.orDivider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  margin: 0 10px;
}

.orDivider::before,
.orDivider::after {
  content: '';
  flex: 1;
  height: 1px;
  background-color: #e0e1eb;
}

.orDivider span {
  font-weight: bold;
  color: #292b3d;
  padding: 0 5px;
}

/* Adjust Form Groups */
.dataOptions .formGroup {
  width: 45%;
}

.dataOptions .formGroup input {
  width: 100%;
}

/* Upload Container */
.uploadContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Message Container */
.messageContainer {
  min-height: 24px; /* Reserve space for messages to prevent layout shifts */
}

/* Disabled Overlay */
.disabledOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  pointer-events: none; /* Allows underlying elements to retain their disabled state */
}

.disabledText {
  color: #292b3d;
  font-size: 14px;
  text-align: center;
  padding: 0 10px;
}

/* Disabled Section Styles */
.disabledSection {
  opacity: 0.6;
  pointer-events: none; /* Prevents interaction */
}

/* Optionally, you can add hover effects only when not disabled */
.disabledSection .iconOption:hover {
  background-color: inherit;
}

/* Tag Input Group */
.tagGroup {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.tagLabel {
  width: 80px;
  font-weight: 600;
  color: #292b3d;
}

/* Responsive Design */
@media (max-width: 768px) {
  .modalContent {
    width: 95%;
    padding: 20px;
  }

  .iconGroup {
    justify-content: center;
  }

  .formGroup {
    margin-bottom: 15px;
  }

  .buttonGroup {
    flex-direction: column;
    gap: 10px;
  }

  .dataOptions {
    flex-direction: column;
    align-items: stretch;
  }

  .dataOptions::before,
  .dataOptions::after {
    display: none;
  }

  .orDivider {
    display: none;
  }

  .dataOptions .formGroup {
    width: 100%;
    margin: 10px 0;
  }

  .uploadContainer {
    flex-direction: column;
    align-items: stretch;
  }

  .tagGroup {
    flex-direction: column;
    align-items: flex-start;
  }

  .tagInput {
    width: 100%;
    margin-top: 10px;
  }
}


/* PopupAddFeed.module.css */

/* ... existing styles ... */

.mappingContainer {
  display: flex;
  flex-direction: column;
}

.mappingRow {
  display: flex;
  align-items: center;
}

.mappingField {
  flex: 1;
  font-weight: 600;
  color: #292b3d;
}

.mappingDropdown {
  flex: 2;
  padding: 8px;
  margin: 0px;
  border: 1px solid #e0e1eb;
  border-radius: 4px;
  font-family: 'Lexend Deca', sans-serif;
  transition: border-color 0.3s;
}

.mappingDropdown:focus {
  border-color: #4758eb;
  outline: none;
}

/* New class for "Found in feed" */
.foundInFeed {
  flex: 2;
  padding: 8px;
  font-family: 'Lexend Deca', sans-serif;
  color: #28a745; /* Green color to indicate success */
  font-weight: 600;
}

/* Styles for Missing Field Checkbox */
.missingFieldLabel {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 14px;
  color: #292b3d;
}

.missingFieldCheckbox {
  margin-right: 5px;
  cursor: pointer;
}

/* Responsive adjustments if needed */
@media (max-width: 768px) {
  .mappingRow {
    flex-direction: column;
    align-items: flex-start;
  }

  .mappingField {
    width: 100%;
  }

  .mappingDropdown {
    width: 100%;
  }

  .foundInFeed {
    width: 100%;
  }

  .missingFieldLabel {
    margin-left: 0;
    margin-top: 5px;
  }
}


/* Add these new styles */
/* General Table Styling */
.mappingTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  font-family: 'Lexend Deca', sans-serif;
}

/* Table Header Styling */
.mappingTable thead {
  background-color: #4758EB;
  color: #ffffff;
  font-weight: bold;
}

.mappingTable th,
.mappingTable td {
  padding: 8px;
  text-align: left;
  border: 0px solid #e0e1eb;
  vertical-align: middle !important; /* Vertically center-align content */
  font-size: 14px;
}

/* Zebra Striping for Better Readability */
.mappingTable tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Hover Effect */
.mappingTable tbody tr:hover {
  background-color: #e0e1eb;
}

/* Adjusting Tooltip Icon Alignment */
.mappingField {
  margin-top: 9px;
  display: flex;          /* Use flexbox */
  align-items: center;    /* Vertically center items within flex container */
}

.tooltip {
  margin: 0; /* Remove margin if present */
  padding: 0; /* Remove padding if present */
}

/* Icon Status Styling */
.statusIconSuccess {
  color: #5CD685; /* Green */
  font-size: 18px;
}

.statusIconWarning {
  color: #f0f000; /* Yellow */
  font-size: 18px;
}

.statusIconError {
  color: #dc3545; /* Red */
  font-size: 18px;
}

/* Checkbox Alignment */
.missingFieldCheckbox {
  transform: scale(1.2);
  cursor: pointer;
  vertical-align: middle;
}

/* Tooltip Styling */
.tooltip {
  position: relative;
  display: inline-block;
  margin-left: 5px;
  color: #4758EB;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: #292B3D;
  color: #fff;
  text-align: left;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 105%;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .mappingTable {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .mappingTable th,
  .mappingTable td {
    padding: 10px;
    font-size: 12px;
  }

  .statusIconSuccess,
  .statusIconWarning,
  .statusIconError {
    font-size: 16px;
  }
}

/* Optional: Sticky Header for Large Tables */
.mappingTable thead th {
  position: sticky;
  top: 0;
  z-index: 2;
}

/* Warning Message Styling */
.warningMessage {
  display: flex;
  align-items: center;
  background-color: #fff3cd; /* Light yellow background */
  color: #856404; /* Dark yellow text */
  padding: 10px;
  border: 1px solid #ffeeba;
  border-radius: 4px;
  margin-top: 10px;
}

.duplicateWarning {
  display: flex;
  align-items: flex-start;
  background-color: #fff3cd; /* Light yellow background */
  color: #856404; /* Dark yellow text */
  padding: 15px;
  border: 1px solid #ffeeba;
  border-radius: 4px;
  margin-top: 10px;
}

.duplicateWarning .warningIcon {
  margin-right: 10px;
  font-size: 24px;
}

.duplicateWarning p {
  margin: 0;
  font-size: 14px;
}

.duplicateWarning strong {
  font-weight: bold;
}


.infoMessage {
  display: flex;
  align-items: flex-start;
  background-color: #e7f3fe; /* Light blue background */
  color: #31708f; /* Dark blue text */
  padding: 15px;
  border: 1px solid #bce8f1;
  border-radius: 4px;
  margin-top: 10px;
}

.infoIcon {
  margin-right: 10px;
  font-size: 24px;
}

.infoMessage span {
  font-size: 14px;
}





/* Credit Balance Card */
.creditBalanceCard {
  background-color: #f9f9f9;
  border: 1px solid #e0e1eb;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.creditBalanceCard .cardHeader {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.creditBalanceCard .cardIcon {
  font-size: 24px;
  color: #4758EB;
  margin-right: 10px;
}

.creditBalanceCard h4 {
  margin: 0;
  font-size: 18px;
  color: #292b3d;
}

/* Assign Users Card */
.assignUsersCard {
  background-color: #f9f9f9;
  border: 1px solid #e0e1eb;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.assignUsersCard .cardHeader {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.assignUsersCard .cardIcon {
  font-size: 24px;
  color: #4758EB;
  margin-right: 10px;
}

.assignUsersCard h4 {
  margin: 0;
  font-size: 18px;
  color: #292b3d;
}

/* Additional Enhancements */
.mappingHeader {
  margin-bottom: 10px;
}

.mappingTable th {
  background-color: #4758EB;
  color: #ffffff;
}

.mappingTable td {
  /* Ensure text doesn't overflow */
  max-width: 200px;
  word-wrap: break-word;
}

.foundInFeed {
  /* Styling for 'Found in Feed' column if needed */
}

/* Button Styles */
.buttonGroup {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

/* Credit Balance and Assign Users Cards */
.creditBalanceCard,
.assignUsersCard {
  margin-top: 20px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .creditBalanceCard,
  .assignUsersCard {
    padding: 15px;
  }

  .creditBalanceCard .cardHeader,
  .assignUsersCard .cardHeader {
    flex-direction: column;
    align-items: flex-start;
  }

  .creditBalanceCard .cardIcon,
  .assignUsersCard .cardIcon {
    margin-bottom: 10px;
  }
}