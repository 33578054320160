/* File: ImageGenerationScheduler.module.css */

/* Existing CSS... */

/* Top Bar Styles */
.topBar {
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin: 20px;
  background-color: #ffffff;
  color: #4758EB;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  gap: 10px;
  flex-wrap: wrap;
}

.headerTextContainer {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 40px;
  flex-grow: 0;
}

.clientDropdown {
  min-width: 300px;
  font-size: 12px;
}

.backButton {
  background-color: #d0d0d0;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.backButton:hover {
  background-color: #7c7c7c;
}

.feedViewerVersion {
  font-size: 14px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 600;
  color: #4758EB;
}

.headerSubtitle {
  font-size: 22px;
  font-family: 'DM Serif Text', serif;
  color: #292B3D;
}

.headerImage {
  height: 100px;
  width: auto;
  padding-top: 20px;
  object-fit: contain;
  margin-left: auto;
}

.logoutBtn {
  background-color: #E0858C;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.logoutBtn:hover {
  background-color: #EB4755 !important;
}

/* Client Error Message */
.clientError {
  display: flex;
  align-items: center;
  color: #EB4755;
  margin-top: 8px;
}

/* Overview Container Styles */
.overviewContainer {
  margin: 20px;
}

/* Tables Wrapper Styles */
.tablesWrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #ffffff;
  color: #292B3D;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Tables Header Styles */
.tablesHeader {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.tableHeader {
  flex: 1;
}

.tableHeader h2 {
  font-size: 18px;
  color: #292B3D;
}

.tableHeader p {
  margin: 0;
  font-size: 14px;
  color: #292B3D;
}

/* Tables Container */
.tablesContainer {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

/* Table Wrapper */
.tableWrapper {
  flex: 1;
  max-height: 275px; /* Fixed height */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Prevent horizontal overflow */
}

/* Table Styles */
.tableContainer {
  width: 100%;
  overflow-x: auto; /* Add horizontal scrolling */
}

.table {
  width: 100%;
  border-collapse: collapse;
  font-family: 'Lexend Deca', sans-serif;
  table-layout: fixed; /* Ensures consistent column widths */
}

.table th,
.table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  word-wrap: break-word; /* Allow long words to break */
  white-space: normal;   /* Allow text to wrap to the next line */
}

.table th {
  background-color: #4758EB;
  position: sticky;
  top: 0;
  z-index: 1;
  color: #ffffff;
}

.table tr:hover {
  background-color: #f5f5f5;
}

/* Icon Button Styles */
.iconButton {
  background-color: #d0d0d0;
  color: #ffffff;
  border: none;
  padding: 6px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.iconButton:hover {
  background-color: #7c7c7c;
}

/* Modal Styles */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(41, 43, 61, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: #ffffff;
  padding: 30px; /* Increased padding for better spacing */
  border-radius: 8px;
  width: 80%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
}

.modalContent h3 {
  font-family: 'Lexend Deca', sans-serif;
  color: #4758EB;
  margin-bottom: 20px;
}

.closeModalButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #4758EB; /* Change color to match theme */
}

.closeModalButton:hover {
  color: #3641b2 !important;
}

.modalSection {
  margin-bottom: 20px;
}

.modalSection h4 {
  font-family: 'Lexend Deca', sans-serif;
  color: #292B3D;
  margin-bottom: 10px;
}

.idList {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 4px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 12px;
  max-height: 200px;
  overflow-y: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

/* Grouped Modified Images Container */
.groupedModifiedImagesContainer {
  max-height: 400px; /* Adjust as needed */
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Modified Image Group */
.modifiedImageGroup {
  border-top: 1px solid #ddd;
  padding-top: 10px;
}

.modifiedImageGroup h5 {
  font-size: 14px;
  color: #4758EB;
  margin-bottom: 10px;
}

/* Image Pairs Container */
.imagePairs {
  display: flex;
  flex-wrap: wrap;
  justify-content: left; /* Center the images */
  margin-top: 20px
}

/* Single Image Pair */
.imagePair {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px; /* Slightly increased gap between image and label */
  width: 110px; /* Adjust width for uniformity */
}

/* Image Wrapper */
.imageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px; /* Fixed width */
  height: 80px; /* Fixed height */
  border-radius: 6px;
  overflow: hidden; /* Ensure content does not overflow */
}


.currentImage {
  border: 4px solid #E0858C; /* Blue border for Current images */
  padding: 5px;
  border-radius: 6px; /* Slightly round the corners */
}

.newImage {
  border: 4px solid #5CD685; /* Green border for New images */
  border-radius: 6px;
}

/* Style for Image Labels */
.imageLabel {
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  margin-top: 6px;
  color: #292B3D;
}

/* Adjust Image Sizes for Smaller Display */
.image {
  width: 40px; /* Set to a more consistent size */
  height: 40px;
  height: auto;
  object-fit: cover;
}

/* Responsive Adjustments for Image Pairs */
@media (max-width: 768px) {
  .imagePairs {
    gap: 10px;
  }
  
  .imagePair {
    width: 100px; /* Smaller width on smaller screens */
  }
  
  .image {
    max-width: 100px;
  }
}

/* Scrollbar Styling (Optional for better aesthetics) */
.groupedModifiedImagesContainer::-webkit-scrollbar {
  width: 10px;
}

.groupedModifiedImagesContainer::-webkit-scrollbar-thumb {
  background: #4758EB;
  border-radius: 5px;
}

.groupedModifiedImagesContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.groupedModifiedImagesContainer::-webkit-scrollbar-thumb:hover {
  background: #3641b2;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .tablesContainer {
    flex-direction: column;
  }

  .tablesHeader {
    flex-direction: column;
  }
}

/* Feed Dates Styles */
.feedDatesContainer {
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardsWrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;
}

.card {
  background-color: #ffffff;
  border: 1px solid #e0e1eb;
  border-radius: 8px;
  padding: 20px;
  flex: 1;
  min-width: 250px;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.statusDot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 15px;
}

.cardContent {
  display: flex;
  flex-direction: column;
}

.cardContent h3 {
  margin: 0;
  font-size: 16px;
  color: #292B3D;
  margin-bottom: 8px;
}

.cardContent p {
  margin: 0;
  font-size: 14px;
  color: #292B3D;
}

/* Scheduling Settings Container */
.schedulingSettingsContainer {
  margin-top: 20px;
  padding: 20px;
  background-color: #ffffff;
  color: #292B3D;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.schedulingSettingsContainer h2 {
  font-size: 18px;
  color: #292B3D;
}

.settingsWrapper {
  display: flex;
  align-items: center; /* Ensures vertical alignment for all elements */
  gap: 20px; /* Adjust space between each setting item */
  justify-content: space-between; /* Distribute space between elements */
}

/* Align toggle labels and dropdown container */
.settingItem {
  display: flex;
  align-items: center; /* Ensures the label and toggle/dropdown are aligned */
  gap: 10px; /* Adds some space between label and input */
}

.toggleLabel {
  display: flex;
  align-items: center;
  gap: 0px; /* Adds space between text and toggle */
  font-family: 'DM Sans', sans-serif;
  color: #292B3D;
  margin-top: 10px;
}


.toggleLabel input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggleLabel input:checked + .toggleSlider {
  background-color: #4758EB;
}

.toggleSlider {
  position: relative;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 34px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.toggleSlider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

.toggleLabel input:focus + .toggleSlider {
  box-shadow: 0 0 1px #4758EB;
}

.toggleSlider::before {
  content: "";
  position: absolute;
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.2s;
}

.toggleLabel input:checked + .toggleSlider::before {
  transform: translateX(20px);
}

.toggleLabel input:disabled + .toggleSlider {
  background-color: #e0e1eb;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4758EB;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

input:disabled + .slider {
  background-color: #e0e1eb;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #4758EB;
}

/* Dropdown label should align horizontally */
.dropdownLabel {
  display: flex; /* Align label and dropdown horizontally */
  align-items: center; /* Vertically center them */
  justify-content: flex-start; /* Align items to the left */
  gap: 10px; /* Space between label and dropdown */
}

.scheduleDropdown {
  margin-left: 10px; /* Adds space between the label and dropdown */
  flex-grow: 1; /* Allows the dropdown to grow */
  max-width: 150px; /* Limits the width of the dropdown */
}

/* Latest Export Button */
.latestExportButton {
  background-color: #d0d0d0;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
  font-family: 'Lexend Deca', sans-serif;
}

.latestExportButton:hover {
  background-color: #7c7c7c;
}

/* Loading, Error, and No Data Containers */
.loadingContainer,
.errorContainer,
.noDataContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
  font-size: 14px;
  color: #292B3D;
}

/* Processing Jobs Header Styles */
.processingJobsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px; /* Adjust spacing as needed */
}

/* Optional: Adjustments for the Processing Jobs Container */
.processingJobsContainer {
  margin-top: 20px;
  padding: 20px;
  background-color: #ffffff;
  color: #292B3D;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.processingJobsContainer h2 {
  font-size: 18px;
  color: #292B3D;
}

.createJobButton {
  background-color: #5CD685; /* Green color */
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
  font-family: 'Lexend Deca', sans-serif;
  display: flex;
  align-items: center;
  gap: 8px;
}

.createJobButton:hover {
  background-color: #94D1A8 !important;
}

.jobsTableWrapper {
  width: 100%;
  overflow-x: auto;
}

.jobsTable {
  width: 100%;
  border-collapse: collapse;
  font-family: 'Lexend Deca', sans-serif;
  table-layout: fixed;
}

.jobsTable th,
.jobsTable td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  word-wrap: break-word;
  white-space: normal;
}

.jobsTable th {
  background-color: #4758EB;
  position: sticky;
  top: 0;
  z-index: 1;
  color: #ffffff;
}

.jobsTable tr:hover {
  background-color: #f5f5f5;
}

/* Create Job Modal */
.createJobForm {
  display: flex;
  flex-direction: column;
}

.formGroup {
  display: flex;
  flex-direction: column;
}

.formGroup label {
  margin-bottom: 5px;
  font-weight: 500;
}

.formGroup input[type="text"],
.formGroup input[type="number"],
.formGroup .selectInput {
  padding: 8px;
  border: 1px solid #e0e1eb;
  border-radius: 4px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
}

.formGroup input[type="number"] {
  width: 100px;
}

.formActions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.submitButton {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px;
}

.submitButton:hover {
  background-color: #3641b2;
}

.cancelButton {
  background-color: #d0d0d0;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px;
}

.cancelButton:hover {
  background-color: #7c7c7c;
}

/* Enhanced Delete Button Styles */
.deleteButton {
  background-color: #EB4755; /* Match logout button background */
  color: #ffffff;
  border: none;
  padding: 7px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
}

.deleteButton:hover {
  background-color: #E0858C !important; /* Match logout button hover color */
}

.deleteButton:disabled {
  background-color: #e0e1eb;
  cursor: not-allowed;
}

.activeToggle {
  cursor: pointer;
}

/* Toggle Switch Styles */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4758EB;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

input:disabled + .slider {
  background-color: #e0e1eb;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #4758EB;
}

/* Dropdown label should align horizontally */
.dropdownLabel {
  display: flex; /* Align label and dropdown horizontally */
  align-items: center; /* Vertically center them */
  justify-content: flex-start; /* Align items to the left */
  gap: 10px; /* Space between label and dropdown */
}

.scheduleDropdown {
  margin-left: 10px; /* Adds space between the label and dropdown */
  flex-grow: 1; /* Allows the dropdown to grow */
  max-width: 150px; /* Limits the width of the dropdown */
}

/* Latest Export Button */
.latestExportButton {
  background-color: #d0d0d0;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
  font-family: 'Lexend Deca', sans-serif;
}

.latestExportButton:hover {
  background-color: #7c7c7c;
}

/* Responsive Adjustments for Processing Jobs */
@media (max-width: 768px) {
  .processingJobsContainer {
    padding: 15px;
  }

  .createJobButton {
    width: 100%;
    justify-content: center;
  }

  .jobsTable th,
  .jobsTable td {
    font-size: 12px;
    padding: 8px;
  }
}

/* Additional Styles for Toggle Sliders in Modals */
.createJobForm .formGroup .toggleLabel input[type="checkbox"] {
  margin-left: 10px;
}

.createJobForm .formGroup .toggleSlider {
  margin-left: 10px;
}

/* Ensure select inputs within forms take full width */
.selectInput {
  width: 100%;
}


/* ImageGenerationScheduler.module.css */

/* Existing styles... */

/* New Styles for Format Transformer Section */
.formatTransformerSection {
  border: 1px solid #E0E1EB;
  padding: 15px;
  border-radius: 8px;
  margin-top: 20px;
  background-color: #F9F9F9;
}

.checkboxGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: 'DM Sans', sans-serif;
  color: #292B3D;
}

.radioGroup {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.radioLabel {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: 'DM Sans', sans-serif;
  color: #292B3D;
}

/* Additional Styles */
.formGroup {
  margin-bottom: 15px;
}

.multiSelect {
  width: 100%;
}

.formActions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.submitButton {
  background-color: #4758EB;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
}

.submitButton:hover {
  background-color: #3949ab;
}

.cancelButton {
  background-color: #ccc;
  color: #292B3D;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
}

.cancelButton:hover {
  background-color: #b3b3b3;
}

/* Responsive Design */
@media (max-width: 600px) {
  .radioGroup {
    flex-direction: column;
  }
}
