/* src/popups/css/PopupUncropped.module.css */

/* CSS Variables for Consistency */
:root {
  --popup-overlay-bg: rgba(41, 43, 61, 0.8);
  --popup-content-bg: #ffffff;
  --primary-color: #4758eb;
  --primary-hover: #3949ab;
  --danger-color: #eb4755;
  --danger-hover: #d43c3a;
  --success-color: #28a745;
  --warning-color: #ffc107;
  --text-color: #292b3d;
  --secondary-text-color: #666666;
  --border-color: #ccc;
  --legend-added-bg: rgba(0, 128, 0, 0.3);
  --legend-cropped-bg: rgba(255, 0, 0, 0.3);
  --canvas-bg: #f9f9f9;
  --overlay-bg: rgba(255, 255, 255, 0.7);
  --processing-text-color: #4758eb;
}

/* Popup Overlay */
.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--popup-overlay-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 10px;
  box-sizing: border-box;
}

/* Popup Content */
.popupContent {
  background-color: var(--popup-content-bg);
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 1200px; /* Increased max-width for better side-by-side display */
  position: relative;
  overflow-y: auto;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Popup Header */
.popupHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff; /* Replace with your desired color */
}

.popupHeader h2 {
  font-family: 'DM Serif Text', serif;
  font-size: 24px;
  color: var(--text-color);
  margin: 0;
}

/* Top Error Message */
.topErrorMessage {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #fdecea; /* Light red background */
  color: var(--danger-color);
  padding: 10px 15px;
  border: 1px solid #f5c6cb;
  border-radius: 8px;
  margin-bottom: 15px;
}

/* Content Wrapper */
.contentWrapper {
  display: flex;
  flex-direction: row; /* Ensure row direction for side-by-side layout */
  flex-wrap: nowrap; /* Prevent wrapping on larger screens */
  gap: 20px;
  justify-content: space-between;
}

/* Original Image Section */
.originalImageSection {
  flex: 1 1 48%; /* Adjusted flex-basis for better side-by-side display */
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.originalImageSection h3 {
  margin: 0 0 10px 0;
  font-size: 18px;
  color: var(--text-color);
}

.imageFigure {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Processed Image Section */
.processedImageSection {
  flex: 1 1 48%; /* Adjusted flex-basis for better side-by-side display */
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.processedImageSection h3 {
  margin: 0 0 10px 0;
  font-size: 18px;
  color: var(--text-color);
}

.processedImageContainer {
  position: relative; /* For positioning the overlay */
  width: 100%;
  height: 500px; /* Same height as CANVAS_SIZE to ensure equal sizing */
  border: 1px solid var(--border-color);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
}

.processedImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* Processing Overlay */
.processingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--overlay-bg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  z-index: 10; /* Ensure it overlays the image */
}

.processingText {
  font-size: 16px;
  color: var(--processing-text-color);
  font-family: 'Lexend Deca', sans-serif;
}

/* Processed Status */
.processedStatus {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: var(--text-color);
}

/* Legend */
.legend {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.legendItem {
  display: flex;
  align-items: center;
  gap: 10px;
}

.addedArea {
  width: 20px;
  height: 20px;
  background-color: var(--legend-added-bg);
  border: 2px dashed rgba(0, 128, 0, 0.6);
  border-radius: 4px;
}

.croppedArea {
  width: 20px;
  height: 20px;
  background-color: var(--legend-cropped-bg);
  border: 2px dashed rgba(255, 0, 0, 0.6);
  border-radius: 4px;
}

/* Controls */
.controls {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.dimensionInput {
  display: flex;
  flex-direction: column;
}

.dimensionInput label {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  color: var(--text-color);
  margin-bottom: 5px;
}

.dimensionInput input {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 14px;
}

.dimensionInput input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(71, 88, 235, 0.2);
}

.originalDimension {
  font-size: 12px;
  color: var(--secondary-text-color);
  margin-top: 4px;
}

/* Canvas Wrapper */
.canvasWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.canvasStage {
  border: 1px solid var(--border-color);
  background-color: var(--canvas-bg);
  border-radius: 8px;
}

/* Loader Container */
.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

/* Error Message */
.errorMessage {
  color: var(--danger-color);
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

/* Top and Bottom Error Messages */
.topErrorMessage,
.bottomErrorMessage {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #fdecea; /* Light red background */
  color: var(--danger-color);
  padding: 10px 15px;
  border: 1px solid #f5c6cb;
  border-radius: 8px;
  margin-bottom: 15px;
}

/* Button Footer */
.buttonFooter {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Button Container */
.buttonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  width: 100%;
}

/* Submit Button */
.submitButton {
  background-color: var(--primary-color);
  color: #ffffff;
  border: none;
  padding: 12px 24px;
  border-radius: 24px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.2s;
  display: flex;
  align-items: center;
  gap: 10px;
}

.submitButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.submitButton:not(:disabled):hover {
  background-color: var(--primary-hover);
  transform: translateY(-2px);
}

.submitButton:not(:disabled):focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(71, 88, 235, 0.4);
}

/* Cancel Button */
.cancelButton {
  background-color: var(--danger-color);
  color: #ffffff;
  border: none;
  padding: 12px 24px;
  border-radius: 24px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.2s;
}

.cancelButton:hover {
  background-color: var(--danger-hover);
  transform: translateY(-2px);
}

.cancelButton:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(235, 71, 85, 0.4);
}

/* Credit Info */
.creditInfo {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  color: var(--secondary-text-color);
}

.creditInfo strong {
  color: var(--text-color);
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .contentWrapper {
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
  }

  .originalImageSection,
  .processedImageSection {
    flex: 1 1 100%; /* Stack vertically */
  }

  .processedImageContainer {
    height: 400px; /* Adjust height when stacking vertically */
  }

  .buttonContainer {
    justify-content: space-between;
  }

  .creditInfo {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .controls {
    align-items: center;
  }

  .dimensionInput {
    width: 100%;
    max-width: 300px;
  }

  .canvasWrapper {
    width: 100%;
  }

  .processedImageContainer {
    max-height: 300px;
    height: 300px; /* Ensure consistent height on smaller screens */
  }

  .buttonContainer {
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
  }

  .creditInfo {
    align-self: flex-end;
  }
}

@media (max-width: 480px) {
  .popupContent {
    padding: 15px;
  }

  .popupHeader h2 {
    font-size: 20px;
  }

  .originalImageSection h3,
  .processedImageSection h3 {
    font-size: 16px;
  }

  .submitButton,
  .cancelButton {
    padding: 10px 20px;
    font-size: 14px;
  }

  .dimensionInput input {
    padding: 6px;
    font-size: 12px;
  }

  .processingText {
    font-size: 14px;
  }

  .buttonContainer {
    flex-direction: column;
    align-items: stretch;
  }

  .creditInfo {
    align-self: stretch;
    justify-content: center;
    text-align: right;
  }
}
