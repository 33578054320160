.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modalContent {
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
    width: 300px;
  }
  
  .closeButton {
    background-color: transparent;
    border: none;
    font-size: 18px;
    color: #666;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  .closeButton:hover {
    color: #333;
  }
  
  .formGroup {
    margin-bottom: 15px;
  }
  
  .formGroup label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .formGroup select,
  .formGroup input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s;
  }
  
  .formGroup select:focus,
  .formGroup input:focus {
    border-color: #555EAA;
  }
  
  .importButton {
    background-color: #555EAA;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .importButton:hover {
    background-color: #6E76D1;
  }
  

  /* Export Button Styles */
.exportButton {
  background-color: #5CC0D6;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-left: 10px; /* Adjust spacing as needed */
}

.exportButton:hover {
  background-color: #94D1A8;
}