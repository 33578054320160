/* src/components/css/PopupCreatePromptCombinationPdp.module.css */

.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4); /* Semi-transparent overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* ensure popup is on top */
  }
  
  .popupContent {
    background-color: #fff;
    width: 450px;
    max-width: 90%;
    border-radius: 8px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.3);
    padding: 20px;
    font-family: 'Lexend Deca', sans-serif;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .popupHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .popupHeader h2 {
    margin: 0;
    font-size: 18px;
    color: #292B3D;
    font-family: 'DM Serif Text', serif;
  }
  
  .closeButton {
    background-color: transparent;
    border: none;
    color: #888;
    background-color: #ffffff;
    cursor: pointer;
    font-size: 16px;
    padding: 8px;
    border-radius: 50%;
    transition: background-color 0.3s;
  }
  .closeButton:hover {
    background-color: #ffffff !important;
    color: #000000 !important;
  }
  
  .popupBody {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .fieldLabel {
    font-size: 14px;
    color: #333;
    margin-bottom: 4px;
  }
  
  .textInput {
    height: 34px;
    padding: 6px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    outline: none;
    transition: border-color 0.3s;
  }
  .textInput:focus {
    border-color: #4758EB;
  }
  
  .errorMessage {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    padding: 8px;
    border-radius: 6px;
  }
  
  .successMessage {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
    padding: 8px;
    border-radius: 6px;
  }
  
  .warningMessage {
    background-color: #fff3cd;
    color: #856404;
    border: 1px solid #ffeeba;
    padding: 8px;
    border-radius: 6px;
  }
  
  .infoMessage {
    background-color: #e7f3fe;
    color: #0c5460;
    border: 1px solid #bee5eb;
    padding: 8px;
    border-radius: 6px;
  }
  
  /* Footer buttons */
  .popupFooter {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
  
  /* Similar to your PdpGeneration styles for filter buttons */
  .createButton {
    background-color: #4758EB;
    color: #ffffff;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 14px;
  }
  .createButton:hover {
    background-color: #848EE0;
  }
  .createButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
