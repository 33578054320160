/* pdpgeneration.module.css */

/* Top Bar */
.topBar {
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
  background-color: #ffffff;
  color: #4758EB;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  gap: 10px;
  flex-wrap: wrap;
}

.topBarLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.topBarActions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.headerTextContainer {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 40px;
  flex-grow: 0;
}

.feedViewerVersion {
  font-size: 14px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 600;
  color: #4758EB;
}

.headerSubtitle {
  font-size: 22px;
  font-family: 'DM Serif Text', serif;
  color: #292B3D;
}

.headerImage {
  height: 100px;
  width: auto;
  padding-top: 20px;
  object-fit: contain;
  margin-left: auto;
}

/* Buttons */
.backButton {
  background-color: #d0d0d0;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}
.backButton:hover {
  background-color: #7c7c7c;
}

.logoutButton {
  background-color: #E0858C;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}
.logoutButton:hover {
  background-color: #EB4755 !important;
}

.refreshButton {
  background-color: transparent;
  color: #d0d0d0;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}
.refreshButton:hover {
  color: #4758EB;
  background-color: #ffffff !important;
}

.clientDropdown {
  min-width: 200px;
  font-size: 12px;
}

/* Filter Section */
.filterSection {
  /* Remove width: 100% */
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px; /* Simplify margins */
}

/* Each row uses a 5-column grid with flexible sizing */
.filterRow {
  display: grid;
  grid-template-columns: 
    80px                       /* column 1: Logic (AND/OR) */
    minmax(100px, 1fr)         /* column 2: Column select */
    minmax(100px, 1fr)         /* column 3: Filter type */
    minmax(150px, 3fr)         /* column 4: Input */
    40px;                      /* column 5: Remove button */
  column-gap: 10px;
  align-items: center;
  width: 100%;
  margin: 0; 
}


.tableImage {
  max-width: 60px; /* Adjust as needed */
  max-height: 60px; /* Adjust as needed */
  width: auto;
  height: auto;
  object-fit: contain;
  border-radius: 4px; /* Optional: Adds slight rounding to images */
}

.filterDropdown {
  width: 100%;
  min-width: 0; 
  font-family: 'Lexend Deca', sans-serif;
  font-size: 12px !important;
  background-color: #f4f4f4;
  border-radius: 4px;
  transition: border-color 0.3s;
  outline: none;
  
  /* Add the desired height */
  height: 34px;
}

/* Ensure other dropdowns and inputs have consistent heights */
.filterLogicSelect,
.filterTypeSelect,
.filterInput {
  height: 34px; /* Match the desired height */
  box-sizing: border-box; /* Ensure padding and borders are included in the height */
}

/* Adjust padding to ensure alignment */
.filterLogicSelect,
.filterTypeSelect {
  padding: 6px 8px;
}

.pageLabel {
  font-size: 12px; /* Adjust to your preferred size (e.g., 12px, 16px) */
  color: #333333;  /* Change to your desired color */
  font-family: 'Lexend Deca', sans-serif;
  margin-left: 8px;
}

.filterTypeSelect {
  height: 34px;
  box-sizing: border-box;
  padding: 6px 8px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s;
  outline: none;
  color: #292B3D;
}

.filterInput {
  padding: 8px;
}

.removeFilterButton {
  background-color: #EB4755;
  color: #ffffff !important;
  border: none;
  padding: 0px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  width: 20px;
  height: 20px;
  font-size: 10px;
}
.removeFilterButton:hover {
  background-color: #E0858C !important;
}
.removeFilterButton svg {
  color: #ffffff !important; 
}

.filterActionsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 0px;
}

.addFilterButton {
  background-color: #5CD685;
  color: #ffffff;
  border: none;
  padding: 10px!important;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px!important;
  height: 40px!important;
}
.addFilterButton:hover {
  background-color: #94D1A8 !important;
}

.applyFilterButton {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 10px!important;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px!important;
  height: 40px!important;
}
.applyFilterButton:hover {
  background-color: #848EE0 !important;
}



.RowDataPopupButton {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 2px !important; /* Reduced padding */
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px !important; /* Increased size for better visibility */
  height: 20px !important;
  box-sizing: border-box; /* Ensures padding is included in size */
}

.RowDataPopupButton:hover {
  background-color: #848EE0 !important;
}

/* Status Container */
.statusContainer {
  margin: 20px;
  font-family: 'Lexend Deca', sans-serif;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.errorMessage {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  padding: 10px;
  border-radius: 8px;
  text-align: left;
}

.successMessage {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  padding: 10px;
  border-radius: 8px;
  text-align: left;
}

.infoMessage {
  background-color: #e7f3fe; 
  color: #0c5460;
  border: 1px solid #bee5eb;
  padding: 10px;
  border-radius: 8px;
  text-align: left;
  display: flex; /* so spinner and text can be aligned */
  align-items: center;
}

.warningMessage {
  background-color: #fff3cd;
  color: #856404;
  border: 1px solid #ffeeba;
  padding: 10px;
  border-radius: 8px;
  text-align: left;
}

/* Column Selection Container */
.columnSection {
  background: #FFF;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.listContainer {
  display: flex;
  align-items: stretch;
  gap: 20px;
}

.scrollableListContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 280px;
}

.scrollableListContainer h3 {
  font-size: 18px;
  font-family: 'Lexend Deca', sans-serif;
  color: #292B3D;
  margin-bottom: 10px;
}

.scrollableList {
  flex: 1;
  overflow-y: auto;
  max-height: 250px;
  background-color: #ffffff;
  border: 1px solid #E0E1EB;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.scrollableList ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.scrollableList li {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Add this */
  padding: 6px;
  margin-bottom: 2px;
  background-color: #F3F3F7;
  border: 1px solid #E0E1EB;
  border-radius: 6px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  color: #292B3D;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.scrollableList li:hover {
  background-color: #E0E1EB;
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);
}

.scrollableList li.selected {
  background-color: #4758EB;
  color: #ffffff;
  border-color: #4758EB;
}

.scrollableList li.selected:hover {
  background-color: #3949ab;
}

.buttonGroupColumnSelection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.buttonGroupColumnSelection button {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  width: 40px;
  height: 40px;
}

.buttonGroupColumnSelection button:hover {
  background-color: #6c7bbf;
}

/* Table Container */
.tableContainer {
  position: relative; /* Add this line */
  margin: 20px;
  margin-bottom: 60px;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  overflow-x: auto;
}

.toolbox {
  display: flex;
  justify-content: flex-start; /* Align buttons to the right */
  align-items: center;       /* Vertically center the buttons */
  gap: 10px;                 /* Space between buttons */
  margin: 20px;              /* Consistent margin with other sections */
  padding: 10px;             /* Optional: Add padding if needed */
  background-color: #ffffff; /* Optional: Background color to match other sections */
  border-radius: 8px;        /* Optional: Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Shadow for depth */
}

.tableOverlay {
  position: absolute; /* Position overlay absolutely within the table container */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2; /* Ensure the overlay sits above the table */
  border-radius: 8px;
}

.tableContainer table {
  border-collapse: collapse;
  min-width: 800px;
  background-color: #fff;
  width: max-content;
}

.tableContainer th, .tableContainer td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  font-size: 0.8rem;
}

.tableContainer th {
  background: #4758EB;
  color: white;
  position: sticky;
  top: 0;
  z-index: 1;
  font-weight: 500;
}

/* Pagination */
.tableControls {
  /* Position it at the bottom center, just like the first page */
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 600px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 2;
  font-family: 'Lexend Deca', sans-serif;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

/* Pagination Total Text */
.paginationTotal {
  font-size: 12px; /* Adjust to desired size (e.g., 14px, 16px) */
  color: #666666;   /* Optional: Change text color if needed */
  font-family: 'Lexend Deca', sans-serif; /* Ensure consistency with other text */
}


.navButton {
  background-color: #4758EB;
  color: white;
  border: none;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  width: 26px;
  height: 26px;
}

.navButton:hover {
  background-color: #565fb3;
}

.navButton:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.navButton:disabled:hover {
  background: #ccc;
}

.pageInput {
  width: 50px; /* Original width */
  text-align: center;
  padding: 4px;
  border: 1px solid #ddd;
  border-radius: 4px;
  
  /* Adjust the width as needed */

  max-width: 100px;
  
  /* Optional: Ensure consistency with other inputs */
  font-size: 12px;
  font-family: 'Lexend Deca', sans-serif;
  transition: border-color 0.3s;
  outline: none;
  background-color: #fff;
}

.pageInput:focus {
  border-color: #565fb3;
}

.inputSelect {
  font-size: 12px;
  font-family: 'Lexend Deca', sans-serif;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px 6px;
  transition: border-color 0.3s;
  outline: none;
  margin-left: 10px;
  margin-right: 8px;
  
  /* Set the desired width */
  width: 80px; /* Example: 100px */
  
  /* Optional: Adjust min and max widths for responsiveness */
  min-width: 80px;
  max-width: 150px;
}

.inputSelect:focus {
  border-color: #565fb3;
}

.noDataContainer {
  text-align: center;
  margin-top: 50px;
}

.noDataMessage {
  font-size: 18px;
  color: #555555;
}


/* Disabled Input and Select Styles */
.tableControls input[type="number"]:disabled,
.tableControls select:disabled {
  background-color: #f0f0f0;
  color: #a0a0a0;
  cursor: not-allowed;
}

.tableControls input[type="number"]::-webkit-inner-spin-button,
.tableControls input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.tableControls input[type="number"] {
  -moz-appearance: textfield;
}


/* Add this new class for the View column */
.viewColumn {
  width: 40px;          /* Set your desired fixed width */
  text-align: center;   /* Centers the button horizontally within the cell */
  box-sizing: border-box; /* Ensures padding is included within the width */
}

.selectColumn {
  width: 40px;         
  text-align: center; 
  box-sizing: border-box;
}


/* Example (like the refreshButton but with your color preference) */
.promptBuilderButton {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.promptBuilderButton:hover {
  background-color: #848EE0 !important;
}

.cancelGenerationButton {
  background-color: #EB4755;
  color: #ffffff;
  border: none;
  padding: 0px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: 10px;
}

.cancelGenerationButton:hover {
  background-color: #E0858C !important;
}

.cancelGenerationButton:disabled {
  background-color: #e0aeb1;
  cursor: not-allowed;
}