.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Lexend Deca', sans-serif;
}

.popupContainer {
  background: #ffffff;
  width: 90%;
  max-width: 700px;
  padding: 30px;
  position: relative;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: 90vh;
}

.closeButton {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background: #E0858C;
  color: #ffffff;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  cursor: pointer;
  font-size: 20px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.closeButton:hover {
  background-color: #EB4755 !important;
}

.header {
  text-align: left;
  margin-bottom: 20px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  color: #333;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  color: #292B3D;
}

.instructions {
  font-size: 14px;
  color: #555;
  margin: 0;
  line-height: 1.4;
}

.inputGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: baseline;
}

.field {
  display: flex;
  align-items: center; /* This aligns the input and label in the center */
  flex: 1;
  min-width: 120px;
}


.fieldLabel {
  font-size: 14px;
  font-weight: 600;
  color: #292B3D;
  margin-bottom: 5px;
}

.input {
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.3s;
  width: 100%;
  height: 40px; /* Ensure consistent height */
  box-sizing: border-box;
}

.input:focus {
  border-color: #848EE0;
  outline: none;
}

.select {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.3s;
  height: 40px; /* Ensure consistent height */
  box-sizing: border-box;
}

.select:focus {
  border-color: #848EE0;
  outline: none;
}

.withLabel {
  font-size: 14px;
  color: #292B3D;
  font-weight: 500;
  margin: 0 5px;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.caseSensitiveGroup {
  display: flex;
  align-items: center;
  gap: 15px;
}

.caseSensitiveLabel {
  font-weight: 600;
  font-size: 14px;
  color: #292B3D;
}

.radioLabel {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #292B3D;
  gap: 5px;
  cursor: pointer;
}

.radioLabel input[type="radio"] {
  margin: 0;
  transform: scale(1.2);
  accent-color: #848EE0;
}

/* Footer Section */
.footer {
  border-top: 1px solid #ddd;
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.updateMessageContainer {
  min-width: 150px; /* Reserve space to prevent shifting */
}

.updateMessage {
  font-size: 14px;
  color: #292B3D;
  font-weight: 600;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.replaceButton {
  background: #4758EB;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.replaceButton:hover {
  background-color: #848EE0 !important;
}

.replaceButton:disabled {
  background-color: #ccc !important;
  cursor: not-allowed;
}
