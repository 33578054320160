/* src/components/css/PopupUpdateCreditBalance.module.css */

.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1500;
    animation: fadeIn 0.3s forwards;
  }
  
  .modalContent {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    position: relative;
    animation: scaleIn 0.3s forwards;
  }
  
  .closeButton {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    cursor: pointer;
    color: #555;
    transition: color 0.3s;
  }
  
  .closeButton:hover {
    color: #000;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .field {
    display: flex;
    flex-direction: column;
  }
  
  .field label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .field input {
    padding: 8px;
    border: 1px solid #E0E1EB;
    border-radius: 4px;
    font-family: 'Lexend Deca', sans-serif;
    transition: border-color 0.3s;
  }
  
  .field input:focus {
    border-color: #4758EB;
    outline: none;
  }
  
  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .submitButton {
    background-color: #4758EB;
    color: #ffffff;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submitButton:hover {
    background-color: #364fc7;
  }
  
  .submitButton:disabled {
    background-color: #a0a0a0;
    cursor: not-allowed;
  }
  
  .cancelButton {
    background-color: #f0f0f0;
    color: #333;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .cancelButton:hover {
    background-color: #dcdcdc !important;;
  }
  
  .error {
    color: #d66b7f;
    font-weight: bold;
  }
  
  .success {
    color: #4caf50;
    font-weight: bold;
  }
  
  /* Modal Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(0.9);
    }
    to {
      transform: scale(1);
    }
  }
  