/* src/popups/PopupBulkActionPages/ImporterPage.module.css */

@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');

.pageContainer {
  padding: 20px;
  font-family: 'Lexend Deca', sans-serif;
  min-height: 100vh;
}

.toggleSwitch {
  display: flex;
  align-items: center;
  background-color: #e0e1eb;
  border-radius: 24px;
  padding: 5px;
  width: fit-content;
  margin: 20px 0;
  gap: 10px;
  justify-content: space-between;
}

.toggleButton {
  background-color: transparent;
  border: none;
  color: #292B3D;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 20px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.internalMode:hover,
.fileMode:hover {
  background-color: #4758EB !important;
  color: white !important;
}

.internalActiveButton,
.fileActiveButton {
  background-color: #4758EB;
  color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.internalActiveButton:hover,
.fileActiveButton:hover {
  background-color: #4758EB !important;
  color: white !important;
}

.internalImportContainer,
.fileImportContainer {
  margin-top: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.fieldGroup {
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
}

.fieldLabel {
  flex: 0 0 120px; /* Increased width for better alignment */
  font-weight: bold;
  color: #292B3D;
  margin-right: 20px;
  align-self: center;
}

.fieldRow {
  display: flex;
  flex: 1;
  gap: 20px;
  width: 100%;
}

.selectContainer {
  flex: 1;
}

.select {
  width: 100%;
  font-family: 'Lexend Deca', sans-serif;
}

.imageTypeContainer {
  flex: 1;
  display: flex;
  align-items: center;
}

.imageTypeLabel {
  margin-right: 10px;
  font-weight: bold;
  white-space: nowrap;
}

.radioGroup {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.radioLabel {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.radioLabel input {
  margin-right: 5px;
}

.importPlaceholder {
  font-size: 14px;
  color: #555555;
  flex: 1; /* Allow the placeholder to take available space */
}

.buttonGroup {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end; /* Align the button to the right */
}

.applyButton {
  background-color: #5CD685;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: all 0.2s ease;
}

.applyButton:hover {
  background-color: #94D1A8 !important;
}

.applyButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.scopeGroup {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.scopeButton {
  padding: 8px 16px;
  border: 1px solid #d0d0d0;
  border-radius: 20px;
  color: #292B3D;
  background-color: transparent;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  transition: all 0.3s ease;
  font-size: 14px;
}

.scopeButton:hover {
  color: #292B3D !important;
  background-color: #E0E1EB !important;
  border-color: #E0E1EB !important;
}

.activeScopeButton {
  background-color: #4758EB;
  color: #ffffff;
  border-color: #4758EB;
}

.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1000;
}

.loadingOverlay p {
  margin-top: 10px;
  font-size: 16px;
  color: #4758EB;
}

.idSelectionContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Ensure dropdowns and image type sections have the same width */
.fieldRow > .selectContainer,
.fieldRow > .imageTypeContainer {
  flex: 1;
}

/* Ensure dropdowns and image type sections have the same width on smaller screens */
@media (max-width: 768px) {
  .fieldGroup {
    flex-direction: column;
    align-items: flex-start;
  }

  .fieldLabel {
    flex: 0 0 auto;
    margin-bottom: 10px;
  }

  .fieldRow {
    flex-direction: column;
    gap: 10px;
  }

  .selectContainer,
  .imageTypeContainer {
    width: 100%;
  }

  .scopeGroup {
    flex-direction: column;
    width: 100%;
  }

  .scopeButton {
    width: 100%; /* Make buttons full width on smaller screens */
  }

  .buttonGroup {
    justify-content: center; /* Center the button on smaller screens */
  }
}

/* New Styles for Column Selection */
.columnSelection {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.selectWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.selectionLabel {
  margin-bottom: 5px;
  font-weight: bold;
  color: #292B3D;
}

.tooltip {
  position: relative;
  display: inline-block;
  margin-left: 5px;
  color: #d0d0d0;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: #292B3D;
  color: #fff;
  text-align: left;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 25px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 768px) {
  .columnSelection {
    flex-direction: column;
    gap: 10px;
  }
}

/* New Styles for Preview Section */
.previewContainer {
  margin-top: 20px;
}

.previewTableContainer {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.previewTable {
  width: 100%;
  border-collapse: collapse;
}

.previewTable th,
.previewTable td {
  padding: 8px 12px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

.previewTable th {
  background-color: #4758EB;
  color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 1;
}

.loadingPreview {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.errorMessage {
  color: red;
  margin-top: 10px;
}

@media (max-width: 768px) {
  /* Adjust preview table for smaller screens */
  .previewTable th,
  .previewTable td {
    padding: 6px 8px;
    font-size: 12px;
  }
}

/* New Styles for To Field Row in File Import */
.toFieldRow {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
}

/* Ensures the placeholder text aligns properly */
.importPlaceholder {
  font-size: 14px;
  color: #555555;
  flex: 1; /* Allow the placeholder to take available space */
}

@media (max-width: 768px) {
  .toFieldRow {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .importPlaceholder {
    font-size: 12px;
  }
}

/* New Styles for To Field Container */
.toFieldContainer {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between label and dropdown */
  flex: 1;
}

/* Updated Styles for To Label */
.toLabel {
  font-weight: bold;
  color: #292B3D;
  display: flex;
  align-items: center;
}

/* Ensure the Select component takes the remaining space */
.toFieldContainer .select {
  flex: 1;
}

/* Adjust Image Type Container to align properly */
.imageTypeContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px; /* Add margin for spacing on smaller screens */
}

@media (max-width: 768px) {
  .columnSelection {
    flex-direction: column;
    gap: 10px;
  }

  .toFieldContainer {
    width: 100%;
  }

  .imageTypeContainer {
    width: 100%;
    margin-top: 5px;
  }
}

/* New Styles for Step Container */
.stepContainer {
  margin-bottom: 30px;
}

.stepHeader {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.stepNumber {
  width: 30px;
  height: 30px;
  background-color: #4758EB;
  color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 10px;
}

.stepTitle {
  font-size: 18px;
  color: #292B3D;
}

/* Adjust file input */
.fileInput {
  margin-top: 10px;
}

@media (max-width: 768px) {
  .stepHeader {
    flex-direction: row;
    align-items: center;
  }

  .stepNumber {
    width: 25px;
    height: 25px;
    font-size: 14px;
  }

  .stepTitle {
    font-size: 16px;
  }
}

